<div class="row mt-5" *ngIf="showTable">
  <div class="col-6"><h2>Resultattabeller</h2></div>
  <div class="offset-3 col-3">
    <form [formGroup]="editForm">
      <label for="numberOfColumns" class="float-end">Välj antal kolumner</label>
      <select class="form-select" formControlName="numberOfColumns">
        <option value="col-12">1</option>
        <option value="col-6">2</option>
        <option value="col-4">3</option>
        <option value="col-3">4</option>
      </select>
     </form>
  </div>
</div>
<div class="row" *ngIf="showTable">
  <div class="{{editForm.value.numberOfColumns}}" *ngFor="let table of tables">
    <div class="card mt-2">
      <div class="card-body">
        <!--Visa vanlig tabell-->
        <table class="table table-bordered border-primary-subtle" *ngIf="!table.showMultipleVariableValues">
          <thead>
            <tr>
              <ng-container *ngFor="let header of table.headers">
                <th [attr.colspan]="header.colspan" width="{{header.width}}">{{header.description}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let gRow of table.groupedRows">
              <ng-container *ngFor="let row of gRow.rows; let index = index">
                <ng-container *ngIf="row.variables.length > 0">
                  <tr>
                    <td *ngIf="gRow.description && index === 0" [attr.rowspan]="gRow.totalVariables" [class.verticalTableTd]="gRow.isVertical">{{gRow.description}} <span *ngIf="gRow.showUnitWithNames">{{variables[0].unit}}</span></td>
                    <td (click)="showChild(row.id)" *ngIf="row.canOpen" class="pointer" [class.fw-bold]="row.isBold">
                      {{row.rowDescription != '' ? row.rowDescription : row.variables[0].short}}
                    </td>
                    <td *ngIf="!row.canOpen">
                      {{row.rowDescription != '' ? row.rowDescription : row.variables[0].short}}
                      <ng-container *ngFor="let variable of row.variables">
                        <div ngbDropdown class="float-end">
                          <button type="button" class="btn btn-xs btn-outline-primary" id="dropdownConfig" ngbDropdownToggle><fa-icon [icon]="faBars"></fa-icon></button>
                          <div ngbDropdownMenu>
                            <button ngbDropdownItem (click)="showHideEdit(variable.showEdit = !variable.showEdit)">{{ variable.showEdit ? "Sluta redigera": "Redigera" }}</button>
                            <button ngbDropdownItem>Visa metodbeskrivning</button>
                          </div>
                        </div>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngFor="let variable of row.variables; index as ix">
                        <span [ngbTooltip]="variable.currentValue + ' ' + variable.unit" [class.bg-secondary]="variable.id == null">{{variable.currentValue}}</span>
                        <div ngbDropdown class="d-inline-block" *ngIf="variable.warningSymbol != '' && variable.warningSymbol != null">
                          <span [ngbTooltip]="variable.warningText" class="px-1" ngbDropdownToggle><i [ngClass]="variable.warningSymbol + ' ' + variable.warningSymbolColor"></i></span>
                          <div ngbDropdownMenu>
                            <div class="container">
                              <h5>Hantera uppmärksamhet</h5>
                              <button type="button" class="btn btn-primary btn-xs">Stäng</button>
                            </div>
                          </div>
                        </div>
                        {{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                        <span *ngIf="row.hasMethodValues" [ngbTooltip]="'Visa mätningar från andra metoder'"><fa-icon [icon]="row.showMethods ? faChevronUp : faChevronDown" (click)="row.showMethods = !row.showMethods"></fa-icon></span>
                        <ng-container *ngIf="row.showMethods">
                          <ng-container *ngFor="let m of variable.measurementMethods">
                            <label class="list-group-item">
                              <input class="form-check-input me-1" type="radio" [value]="m.id" name="metodRadio">{{m.name}} : {{m.value}}
                            </label>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td></td>
                    <td>
                      <!--TODO: Fråga Pekka om jag kan göra såhär eller om det kan bli annan ordning?-->
                      <ng-container *ngFor="let variable of row.variables; index as ix">
                        <span class="tooltip-container text-nowrap" *ngFor="let formel of variable.calculationJson?.formler; index as i" [ngbTooltip]="graderingContent">
                          <span *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                            {{formel.graderingsTecken}}{{formel.startValue}}{{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                          </span>
                          <span *ngIf="variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                            {{formel.beraknat}}{{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                          </span>
                          <ng-template #graderingContent>
                              <table class="table border table-fixed">
                              <thead>
                                <tr>
                                  <!--<th *ngIf="variable.calculationJson.kontyp != 0">Kön</th>-->
                                  <th></th>
                                  <th>Gradering</th>
                                </tr>
                              </thead>
                              <tbody *ngFor="let formel of variable.calculationJson?.formler; index as i">
                                <tr [ngClass]="formel.graderingstyp == 7 ? 'fw-bold' : ''" *ngIf="formel?.formel && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                                  <!--<td *ngIf="variable.calculationJson.kontyp != 0">{{formel.kon == 1 ? 'Kvinna' : 'Man'}}</td>-->
                                  <td>
                                    <ng-container *ngIf="variable.calculationJson.intervalltyp == 2">
                                      {{formel.startValue}}
                                      {{formel.graderingsTecken}}
                                      <span *ngIf="formel.graderingstyp != 1 && formel.graderingstyp != 8 && formel.graderingstyp != 7"> - </span>
                                      <span *ngIf="formel.graderingstyp == 1"> > </span>
                                      <span *ngIf="formel.graderingstyp == 8"> <</span>
                                    </ng-container>
                                    {{formel.formel}} <b>{{formel.intervalltyp == 1 ? ' = ' + formel.beraknat : ''}}</b> {{variable.unit}}
                                  </td>
                                 <td>{{formel.graderingsText}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </ng-template>
                        </span>
                      </ng-container>
                    </td>
                  </tr>
                  <!--Fäll ut om den är sammansatt-->
                  <!--variable.currentValue != '' && variable.currentValue != null &&-->
                  <tr *ngFor="let variable of row.variables">
                    <ng-container *ngIf="row.showChildren == true">
                      <td [class.fw-bold]="row.isBold">
                        <span class="px-3">{{variable.shortName}}</span>

                        <div ngbDropdown class="float-end">
                          <button type="button" class="btn btn-xs btn-outline-primary" id="dropdownConfig" ngbDropdownToggle><fa-icon [icon]="faBars"></fa-icon></button>
                          <div ngbDropdownMenu>
                            <button ngbDropdownItem (click)="showHideEdit(variable.showEdit = !variable.showEdit)">{{ variable.showEdit ? "Sluta redigera": "Redigera" }}</button>
                            <button ngbDropdownItem>Visa metodbeskrivning</button>
                          </div>
                        </div>
                      </td>
                      <td [ngbTooltip]="variable.currentValue + ' ' + variable.unit" [class.bg-secondary]="variable.id == null">{{variable.currentValue}}</td>
                      <td></td>
                      <td [ngbTooltip]="graderingContent">
                        <table>
                          <tbody *ngFor="let formel of variable.calculationJson?.formler; index as i">
                            <tr>
                              <td>
                                <span class="text-nowrap" *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                                  {{formel.graderingsTecken}}{{formel.startValue}}
                                </span>
                                <span class="text-nowrap" *ngIf="(undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0) && variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                                  {{formel.beraknat}}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <ng-template #graderingContent>
                        <table class="table border">
                          <thead>
                            <tr>
                              <!--<th *ngIf="variable.calculationJson.kontyp != 0">Kön</th>-->
                              <th></th>
                              <th>Gradering</th>
                            </tr>
                          </thead>
                          <tbody *ngFor="let formel of variable.calculationJson?.formler; index as i">
                            <tr [ngClass]="formel.graderingstyp == 7 ? 'fw-bold' : ''" *ngIf="formel?.formel && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                              <!--<td *ngIf="variable.calculationJson.kontyp != 0">{{formel.kon == 1 ? 'Kvinna' : 'Man'}}</td>-->
                              <td class="text-nowrap">
                                <ng-container *ngIf="variable.calculationJson.intervalltyp == 2">
                                  {{formel.startValue}}
                                  {{formel.graderingsTecken}}
                                  <span *ngIf="formel.graderingstyp != 1 && formel.graderingstyp != 8 && formel.graderingstyp != 7"> - </span>
                                  <span *ngIf="formel.graderingstyp == 1"> > </span>
                                  <span *ngIf="formel.graderingstyp == 8"> <</span>
                                </ng-container>
                                {{formel.formel}} <b>{{formel.intervalltyp == 1 ? ' = ' + formel.beraknat : ''}}</b> {{variable.unit}}
                              </td>
                              <td>{{formel.graderingsText}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot *ngIf="table.footer">
            <tr>
              <ng-container *ngFor="let f of table.footer">
                <td [attr.colspan]="f.colspan"><span *ngIf="f.description != ''">{{f.description}}</span> <span *ngFor="let variable of f.variables">{{variable.shortName}} = {{variable.currentValue}}</span></td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
        <!--Tabell med värden kolumnvis-->
        <table class="table table-bordered border-primary-subtle" *ngIf="table.showMultipleVariableValues">
          <thead>
            <tr>
              <ng-container *ngFor="let header of table.headers">
                <th [attr.colspan]="header.colspan" width="{{header.width}}">{{header.description}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let gRow of table.groupedRows">
              <tr *ngFor="let row of gRow.rows; let index = index">
                <td>
                  {{gRow.description}} <span *ngIf="gRow.showUnitWithNames">({{variables[0].unit}})</span>
                  <span *ngIf="row.hasMethodValues" [ngbTooltip]="'Visa mätningar från andra metoder'"><fa-icon [icon]="row.showMethods ? faChevronUp : faChevronDown" (click)="row.showMethods = !row.showMethods"></fa-icon></span>
                  <ng-cotainer *ngIf="row.showMethods">

                  </ng-cotainer>
                </td>
                <!--TODO: Fråga Pekka om jag kan göra såhär eller om det kan bli annan ordning?-->
                <ng-container *ngFor="let variable of row.variables; index as ix">
                  <td [class.bg-secondary-subtle]="variable.id == null">
                    {{variable.currentValue}}
                    <!--<ng-container *ngFor="let formel of variable.calculationJson?.formler; index as i">
                      <ng-container *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                        {{formel.graderingsTecken}}{{formel.startValue}}{{ix == 0 ? ' / ' : ''}}
                      </ng-container>
                      <ng-container *ngIf="variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                        {{formel.beraknat}}{{ix == 0 ? ' / ' : ''}}
                      </ng-container>
                    </ng-container>-->
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
          <tfoot *ngIf="table.footer">
            <tr>
              <ng-container *ngFor="let f of table.footer">
                <td [attr.colspan]="f.colspan"><span *ngIf="f.description != ''">{{f.description}}</span><span *ngFor="let variable of f.variables">{{variable.shortName}} = {{variable.currentValue}}</span></td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
<button (click)="getData(showTable = !showTable)" class="btn btn-primary my-5">{{showTable ? 'Dölj tabell med resultat' : 'Visa tabell med resultat'}}</button>

