import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { faPlus, faChevronUp, faChevronDown, faTrash, faCopy, faPaste, faInfoCircle, faCalculator, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Clipboard } from "@angular/cdk/clipboard";
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/

import { OnInit } from '@angular/core';
import { CalculationService, FormTecken, Formular, Gradering, GrunddataDto, SysInfComparisonSymbol, Tecken, VariabelFormularFormController } from '../../../core/services/calculation.service';
import { EchoVariablesDto } from '../../../model/undersokningsvarianter/echo-variables-dto';
import { Observable } from 'rxjs';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gradering-formel-echovariable-vers3',
  templateUrl: './gradering-formel-echovariable-vers3.component.html',
  styleUrls: ['./gradering-formel-echovariable-vers3.component.scss']
})
export class GraderingFormelEchoVariableVers3Component implements OnInit, AfterContentChecked {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private changeDetector: ChangeDetectorRef
  ) { }
  //Ikoner
  faPlusCircle = faPlusCircle;
  faPlus = faPlus;
  faTrash = faTrash;
  faCopy = faCopy;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faPaste = faPaste;
  faInfoCircle = faInfoCircle;
  faCalculator = faCalculator;

  id: number;
  @Input() intervallTyp: number;
  @Input() format: string;
  @Input() konTyp: number;
  @Input() kon: number;
  @Input() group: Formular;
  @Input() grunddata: GrunddataDto;
  @Output() grunddataOpen = new EventEmitter<boolean>();
  @Output() allFormulaData = new EventEmitter<VariabelFormularFormController>();
  @Output() message = new EventEmitter<string>();
  @Input() cVariable: EchoVariablesDto;
  @Input() formChanged: boolean;

  //Graderingar
  //graderingarLista: Gradering[] = [
  //  { id: 1, comparisonSymbol: '', shortDesc: 'Måttligt till uttalat ög', description: '{variabel} måttligt till uttalat ög', tooltip: '{variabel} måttligt till uttalat övre gräns', intervalltyp: 2, graderingsGrupp: 1 },
  //  { id: 2, comparisonSymbol: '', shortDesc: 'Måttligt', description: '{variabel} måttligt', tooltip: '{variabel} uttalat', intervalltyp: 2, graderingsGrupp: null },
  //  { id: 3, comparisonSymbol: '', shortDesc: 'Måttligt till uttalat ng.', description: '{variabel} måttligt till uttalat ng.', tooltip: '{variabel} måttligt till uttalat nedre gräns', intervalltyp: 2, graderingsGrupp: 1 },
  //  { id: 4, comparisonSymbol: '', shortDesc: 'Lätt till måttligt ög.', description: '{variabel} lätt till måttligt ög.', tooltip: '{variabel} lätt till måttligt övre gräns', intervalltyp: 2, graderingsGrupp: 2 },
  //  { id: 5, comparisonSymbol: '', shortDesc: 'Lätt', description: '{variabel} lätt', tooltip: '{variabel} lätt', intervalltyp: 2, graderingsGrupp: null },
  //  { id: 6, comparisonSymbol: '', shortDesc: 'Lätt till måttligt ng', description: '{variabel} lätt till måttligt ng.', tooltip: '{variabel} lätt till måttligt nedre gräns', intervalltyp: 2, graderingsGrupp: 2 },
  //  { id: 13, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 2, graderingsGrupp: null, isMedelvarde: true },
  //  { id: 7, comparisonSymbol: '', shortDesc: 'Normalt', description: '{variabel} är normalt.', tooltip: '{variabel} är normalt', intervalltyp: 2, graderingsGrupp: null },
  //  { id: 8, comparisonSymbol: '', shortDesc: 'Nedre normalområde', description: '{variabel} nedre normalområde', tooltip: '{variabel} nedre normalområde', intervalltyp: 2, graderingsGrupp: null },
  //  { id: 9, comparisonSymbol: '', shortDesc: 'Övre normalområde', description: '{variabel} övre normalområde', tooltip: '{variabel} övre normalområde', intervalltyp: 1, graderingsGrupp: null },
  //  { id: 91, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området!', intervalltyp: 2, graderingsGrupp: null, deleted: true },
  //  { id: 14, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 1, graderingsGrupp: null, isMedelvarde: true },
  //  { id: 9, comparisonSymbol: '', shortDesc: 'Övre normalområde', description: '{variabel} övre normalområde', tooltip: '{variabel} övre normalområde', intervalltyp: 1, graderingsGrupp: null },
  //  { id: 10, comparisonSymbol: '', shortDesc: 'Normalt', description: '{variabel} är normalt', tooltip: '{variabel} är normalt', intervalltyp: 1, graderingsGrupp: null },
  //  { id: 11, comparisonSymbol: '', shortDesc: 'Nedre normalområde', description: '{variabel} nedre normalområde', tooltip: '{variabel} nedre normalområde', intervalltyp: 1, graderingsGrupp: null },
  //  { id: 12, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området', intervalltyp: 1, graderingsGrupp: null, hide: true }
  //];
  //Valideringstexter (som inte används)
  /*validation_messages: ValidationErrors = [{ id: 1, type: 'maxLength', message: 'För många tecken.' },
      { id: 2, type: 'pattern', message: 'Fältet får bara innehålla siffror och kommatecken' },
      { id: 3, type: 'validateCompareIntervalUnder', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 4, type: 'validateCompareIntervalOver', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 5, type: 'min', message: 'Omöjligt min-värde' },
      { id: 6, type: 'max', message: 'Omöjligt max-värde' },
      { id: 7, type: 'required', message: 'Du måste ange ett värde' },
      { id: 8, type: 'parantesesCount', message: "Formlen måste innehålla lika många '(' som ')'" }];*/

  intervallFormTecken: FormTecken;
  submitted = false;

  editVariableGraderingForm = this.fb.group({
    graderingar: this.fb.array([])
  });
  //////////
  ///
  ngOnInit() {
    this.format = this.format?.replaceAll(/#/g, "X").replaceAll(/,/g, ".");
    //Sätt texter med alias
    this.seGraderingstexterMedAlias();
    //this.setForm();
    //this.graderingarLista.forEach(_ => {
    //  if (_.intervalltyp == this.intervallTyp)
    //    this.addGradering(_.id, _.comparisonSymbol, "", _.description, _.shortDesc, _.tooltip, _.intervalltyp, _.deleted == true ? true : false, _.graderingsGrupp, _.hide, _.isMedelvarde);
    //});
    this.setGradering();
    //this.graderingar.controls.forEach((_, index) => {
    //  _.get("tecken").patchValue(this.getTecken(index));
    //});
    this.changeDetector.detectChanges();
  }
  //////////
  ///
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  //////////
  ///
  setForm() {
    //let formTecken: FormTecken = {
    //  konId: this.kon,
    //  intervalltyp: this.intervallTyp,
    //  graderingar: this.graderingarLista.filter(_ => _.intervalltyp === this.intervallTyp)
    //}
    //this.intervallFormTecken = formTecken;
  }

  //////////
  ///
  seGraderingstexterMedAlias() {
    //Visa med variabelnamn
    //this.graderingarLista.forEach(_ => {
    //  _.klarText = _.description.replaceAll("{variabel}", this.cVariable.shortName);
    //  _.tooltip = _.tooltip.replaceAll("{variabel}", this.cVariable.shortName);
    //});
  }
  //////////
  ///
  showFormula(e: any, index: number, typ: number, kon: number) {
    this.graderingar.controls[index].get("formula").setValue(e.formel);
    //if (e.showErrorMessageParanteses)
    //  this.graderingar.controls[index].get("formula").setErrors({ 'parantesesCount': true })
  }
  //////////
  ///
  showFormulaAnswer(e: any, teckenId: number, konId: number) {
    console.log("Svar: " + e);
  }
  //////////
  ///
  get graderingar() {
    return this.editVariableGraderingForm.get('graderingar') as FormArray;
  }
  //////////
  ///
  deleteGradering(id: number) {
    let index = this.graderingar.controls.findIndex(_ => _.get("id").value == id);
    const formel: VariabelFormularFormController = {
      id: this.graderingar.controls[index].value,
      formel: null,
      startValue: null,
      visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTecken: null,
      graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
      //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      konId: this.kon,
      deleted: true
    };
    this.allFormulaData.emit(formel);


    //Rensa bort värdet
    this.graderingar.controls[index]?.get("formula")?.patchValue("");
    //Deletemarkera
    this.graderingar.controls[index]?.reset();
    //this.intervallFormTecken.tecken.find(_ => _.selectedControlIndex === index).selected = false;
    this.graderingar.removeAt(index);
    //this.graderingar.controls.forEach((_, i) => {
    //  this.getTecken(i);
    //});
  }
  //////////
  ///
  formulaChange(id: number, input: string = "") {
    let index = this.graderingar.controls.findIndex(_ => _.get("id").value == id);
    // TODO: Validera - Kolla min och max. Kolla så att värdet är högre respektive lägre än
    if (input != "")
      this.graderingar.controls[index]?.get("formula").patchValue(input);
    this.uppdateraGraderingsText(index);

    //this.allFormulaData.emit(formel);
    this.graderingar.controls?.forEach((_, i) => {

      const formel: VariabelFormularFormController = {
        id: _.get("id").value,
        formel: _.get("formula").value,
        //formel: this.graderingar.controls[index]?.get("formula").value,
        startValue: _.get("startValue")?.value.toString().replace(/\,/g, '.') ?? "",
        visaEjISvar: _.get("hideInSvar")?.value ?? false,
        normalOmrade: _.get("normalOmrade")?.value ?? false,
        visaVarningssymbol: _.get("visaVarningssymbol")?.value ?? false,
        intervallTyp: this.intervallTyp,
        graderingsText: _.get("utlatande")?.value,
        graderingsTecken: _.get("tecken")?.value,
        //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
        konId: this.kon
      };
      this.allFormulaData.emit(formel);
      this.uppdateraGraderingsText(i);
    });


    //Sortera i rätt ordning
    this.graderingar.controls.sort((a, b) => (Number(a.get("formula")?.value) > Number(b.get("formula")?.value)) ? -1 : 1);
  }
  //////////
  ///
  visaEjISvarChange(index: number) {
    const formel: VariabelFormularFormController = {
      reftypId: Number(this.graderingar.controls[index].get("tecken").value),
      formel: this.graderingar.controls[index].get("formula").value,
      visaEjISvar: this.graderingar.controls[index].get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp
    };
    this.allFormulaData.emit(formel);
  }
  //////////
  ///
  uppdateraGraderingsText(index: number) {
    let utlatandetext = this.graderingar.controls[index]?.get("utlatande")?.value.replaceAll("{variabel}", this.cVariable.shortName);
    this.graderingar.controls[index]?.get("graderingstextKlartext")?.patchValue(utlatandetext);
  }
  //////////
  ///
  setGradering() {
    // Om det finns sparade graderingar
    if (this.cVariable.calculationJson && this.cVariable.calculationJson.formler) {
      this.group.formcontroller.forEach((g, i) => {
        this.cVariable.calculationJson.formler.filter(f => f.kon == this.kon).sort((a, b) => Number(a.beraknat) > Number(b.beraknat) ? -1 : 1).flatMap((_, i) => {
          //this.cVariable.calculationJson.intervalltyp == this.intervallTyp
            this.addGraderingsNiva(_.id, _.graderingsTecken, _.formel, _.startValue, _.graderingsText, _.visaEjISvar, _.visaVarningssymbol, _.normalOmrade);
          this.graderingar.controls.forEach((g, index) => {
            if (_.intervalltyp == this.intervallTyp) {
              //this.graderingar.controls[index]?.get("id")?.patchValue(_.graderingstyp);
              /*this.graderingar.controls[index]?.get("kon")?.patchValue(_.kon);*/
              const formel: VariabelFormularFormController = {
                id: _.id,
                graderingsText: _.graderingsText,
                graderingsTecken: _.graderingsTecken,
                formel: _.formel,
                visaEjISvar: _.visaEjISvar,
                intervallTyp: this.intervallTyp,
                konId: _.kon,
                startValue: _.startValue ?? "",
                normalOmrade: _.normalOmrade,
                visaVarningssymbol: _.visaVarningssymbol
              };
              //this.selectTecken(formel.reftypId, index);
              this.allFormulaData.emit(formel);
            }
          });
        });
      });
    }
  }

  //////////
  ///
  private validateCompareIntervalUnder(index: number): ValidatorFn | null {
    //Fortsätt här med att validera så att värdet är lägre än ocan och högre än värdet under
    return function validate(): any {

      //Kolla värdet före
      let prevGradValue;

      if (index != -1)
        prevGradValue = this.graderingar.controls[index + 1]?.get('formula')?.value ?? null;
      if (prevGradValue != null)
        for (var i = this.graderingar.controls.length; i--;) {
          if (i < index && this.graderingar.controls[i]?.get("formula")?.value > prevGradValue && this.graderingar.controls[i]?.get("deleted")?.value != true)
            return false;
          else
            return true;
        }
      return null;
    };
  }
  //////////
  ///
  showHideGrunddata(showGrunddata: boolean) {
    if (showGrunddata == true)
      this.grunddataOpen.emit(true);
    else
      this.grunddataOpen.emit(false);
  }
  //////////
  ///
  showMessage(message: string) {
    this.message.emit(message);
  }
  //////////
  ///
  showGrunddataMessage(text: string) {
    this.message.emit(text);
  }
  //////////
  ///
  copyFormula(formula: string) {
    this.clipboard.copy(formula);
  }
  //////////
  ///Coding With Minecraft
  pasteFormula(controlindex: number) {
    //let copiedText = navigator.clipboard.read();
    this.graderingar.controls[controlindex].get("formula").setValue('kopierad text ska vara här');
    //let copiedText = navigator.clipboard.readText();
    //navigator.clipboard.readText().then(clipText => {
    //  console.log(clipText);
    //});
  }
  //////////
  ///
  getStartValue(index: number): number | null {
    let startInterval;
    for (var i = this.graderingar.controls.length; i--;) {
      if (i < index && this.graderingar.controls[i]?.get("formula")?.value != "" && this.graderingar.controls[i]?.get("deleted")?.value != true) {
        //Beroende på formatet ska 1 dras bort från sista decimalen
        let format = this.format.replace(/X/g, '0');
        let startValue = this.graderingar.controls[i].get("formula").value.toString().replace(/\,/g, '.');
        let numberToTakeAway: string = format.slice(0, -1) + '1';


        startInterval = Number(startValue) - Number(numberToTakeAway);
        console.log(this.graderingar.controls[i].get("formula").value);
        this.graderingar.controls[index].get("startValue").patchValue(startInterval);
        //this.formulaChange(index);
        break;
      }
      else {
        //Om det är den första graderingen ska maxvärdet sättas som start
        startInterval = this.cVariable.impossibleValueMax;
        this.graderingar.controls[index].get("startValue").patchValue(startInterval);
      }
      
      //TODO: Sätt även symboler på första och sista graderingen. 
      //else {
      //  this.formulaChange(index);
      //  break;
      //}
    }
    //console.log("startvalue" + startInterval);
    return startInterval;
  }
  //getTecken(index: number): string {
  //  //TODO: Fortsätt här att fixa med tecknen.

  //  const gradering = this.graderingarLista.filter((_, i) => _.intervalltyp === this.intervallTyp);

  //  if (index !== 0 && index !== gradering.length - 1) {
  //    return "-";
  //  } else if (index === 0) {
  //    this.graderingar.controls[index].get("tecken").patchValue("-");
  //    return "-";
  //  } else {
  //    this.graderingar.controls[gradering.length - 1].get("tecken").patchValue("-");
  //    return "-";
  //  }
  //}
  addGraderingsNiva(id: number, tecken: string = "", formula: string = "", startValue: string = "", utlatande: string = "", visaEjISvar: boolean = false, visaVarningssymbol: boolean = false, normalOmrade: boolean = false) {
    if (id == null)
      id = this.graderingar.controls.length > 0 ? Number(this.graderingar.controls[this.graderingar.controls.length - 1]?.get("id")?.value + 1) : 1;
    const graderingForm = this.fb.group({
      id: id,
      tecken: tecken,
      formula: [formula, [Validators.required, Validators.max(this.cVariable.impossibleValueMax), Validators.min(this.cVariable.impossibleValueMin), Validators.pattern(/^[0-9]+\.?[0-9]*$/), Validators.maxLength(this.cVariable.format?.length)]],
      startValue: startValue,
      utlatande: [utlatande, Validators.required],
      hideInSvar: [visaEjISvar == true ? true : false],
      visaVarningssymbol: [visaVarningssymbol == true ? true : false],
      normalOmrade: [normalOmrade == true ? true : false],
      graderingstextKlartext: []
    });
    this.graderingar.push(graderingForm);
  }
}
