

<form [formGroup]="editVariableGraderingForm">
  <div class="modal-body">
    <h2>Graderingar {{cVariable.description ? cVariable.description : cVariable.shortName}}</h2>
    <div formArrayName="graderingar">
      <cdk-accordion class="accordion mt-2" *ngFor="let graderingForm of graderingar.controls; let groupIndex=index">
        <cdk-accordion-item [formGroup]="graderingForm"
                            #accordionItem="cdkAccordionItem"
                            class="accordion-item"
                            role="button"
                            tabindex="0"
                            [attr.id]="'accordion-header-' + groupIndex"
                            [attr.aria-expanded]="accordionItem.expanded"
                            [attr.aria-controls]="'accordion-body-' + groupIndex" *ngIf="(graderingForm.value.hide != true && graderingForm.value.isMedelvarde != true)">
          <!--<div class="accordion-item-header w-100 disable_pointer bg-secondary">
          </div>-->
          <div class="accordion-item-header w-100 disable_pointer">
            <div class="row">
              <div class="col-12 align-self-end text-end">
                <span (click)="deleteGradering(graderingForm.value.id)" [ngbTooltip]="'Radera'" class="pointer btn-close"></span>
              </div>
              </div>
            <div class="row">
              <div class="col-lg-2">
                <label [ngClass]="intervallTyp == 1 ? 'offset-lg-1' : ''" for="to-{{groupIndex}}">{{intervallTyp == 1 ? 'Formel' : 'Värde'}}:</label>
                <div class="row">
                  <div class="col-lg-1 pt-1" *ngIf="intervallTyp == 1">
                    <fa-icon [icon]="faCalculator" class="pointer" [ngbTooltip]="'Skapa formel'" (click)="accordionItem.toggle()"></fa-icon>
                  </div>
                  <div class="col-lg-10" *ngIf="intervallTyp == 1">
                    <input id="to-{{groupIndex}}" *ngIf="graderingForm.value.formula != ''" type="text" formControlName="formula" [ngbTooltip]="'Redigera formel'" readonly class="form-control-plaintext pointer" (change)="formulaChange(graderingForm.value.id)" (click)="accordionItem.toggle()">
                    <button class="btn btn-xs mt-1" *ngIf="graderingForm.value.formula == '' && !accordionItem.expanded" (click)="accordionItem.toggle()">Skapa formel</button>
                  </div>
                  <div class="col-lg-12" *ngIf="intervallTyp == 2">
                    <input id="to-{{groupIndex}}" type="text" formControlName="formula" class="form-control" [mask]="format ?? 'XX'" (change)="formulaChange(graderingForm.value.id, $event.target.value)">
                    <!--<div *ngFor="let validation of validation_messages.formula">
        <div *ngIf="graderingForm.controls['formula']?.hasError(validation.type) && (graderingForm.controls['formula']?.dirty || graderingForm.controls['formula']?.touched)">
          {{ validation.message }}
        </div>
      </div>-->
                  </div>
                  <div class="col-lg-2 pt-1" *ngIf="graderingForm.value.formula != '' && intervallTyp == 1">
                    <fa-icon [icon]="faCopy" class="pointer" (click)="copyFormula(graderingForm.value.formula)" [ngbTooltip]="'Kopiera formel'"></fa-icon>
                  </div>
                  <!--<div class="col-lg-1 pt-1" *ngIf="graderingForm.value.formula == '' && clipboard && intervallTyp == 1">
      <fa-icon [icon]="faPaste" class="pointer" (click)="pasteFormula(groupIndex)" [ngbTooltip]="'Klistra in formel'"></fa-icon>
    </div>-->
                </div>
              </div>
              <div class="col-lg-1 d-flex justify-content-end text-right">
                <input class="form-control-plaintext mt-0 pt-0" readonly type="text" id="tecken" formControlName="tecken">
                <!--{{isLast ? '≤': ''}}{{isFirst ? '≤': ''}}{{!isLast && !isFirst ? '-': ''}}-->
              </div>
              <div class="col-lg-1 pt-4">
                <input id="to-{{groupIndex}}" type="text" formControlName="startValue" [mask]="format ?? 'XX'" class="form-control" hidden>
                {{getStartValue(groupIndex) | mask: format}}
              </div>
              <div class="col-lg-4">
                <label for="description-{{groupIndex}}">Text:</label>
                <input id="description-{{groupIndex}}" type="text" formControlName="utlatande" class="form-control" (change)="formulaChange(graderingForm.value.id)">
                <small><i>{{graderingForm.value.graderingstextKlartext}}</i></small>
              </div>
              <div class="col-lg-4 pt-1">
                <br>
                <input class="form-check-input" type="checkbox" value="" id="hideInSvar{{groupIndex}}" (change)="formulaChange(graderingForm.value.id)" formControlName="hideInSvar">
                <label class="form-check-label px-1" for="hideInSvar{{groupIndex}}">
                  Visa inte i utlåtandet
                </label>
                <br>
                <input class="form-check-input" type="checkbox" value="" id="visaVarningssymbol{{groupIndex}}" (change)="formulaChange(graderingForm.value.id)" formControlName="visaVarningssymbol">
                <label class="form-check-label px-1" for="visaVarningssymbol{{groupIndex}}">
                  Visa varningssymbol
                </label>
                <br>
                <input class="form-check-input" type="checkbox" value="" id="normalOmrade{{groupIndex}}" (change)="formulaChange(graderingForm.value.id)" formControlName="normalOmrade">
                <label class="form-check-label px-1" for="normalOmrade{{groupIndex}}">
                  Normalområde
                </label>
              </div>
              <div class="col-lg-1 pt-3 d-flex justify-content-end" *ngIf="intervallTyp != 2" (click)="accordionItem.toggle()">
                <span class="example-accordion-item-description">
                  <!--<fa-icon [icon]="accordionItem.expanded ? faChevronUp: faChevronDown" class="pointer"></fa-icon>-->
                </span>
              </div>
            </div>
            {{formula}}
          </div>
          <div class="accordion-item-body"
               [style.display]="accordionItem.expanded ? '' : 'none'"
               [attr.id]="'accordion-body-' + groupIndex"
               [attr.aria-labelledby]="'accordion-header-' + groupIndex">
            <div class="row text-end mt-0 pt-0"><span (click)="accordionItem.toggle()" class="btn btn-link text-end">Dölj</span></div>
            <app-create-formula [type]="graderingForm.value.tecken"
                                [cVariable]="cVariable"
                                [formel]="graderingForm.value.formula"
                                [controlId]="groupIndex"
                                (formulaChanged)="formulaChange(graderingForm.value.id)"
                                (formula)="showFormula($event, groupIndex, graderingForm.value.tecken, group.konId)"
                                [grunddata]="grunddata"
                                (formulaAnswer)="showFormulaAnswer($event, graderingForm.value.tecken, group.konId)"
                                (openGrunddata)="showHideGrunddata($event)"
                                (message)="showMessage($event)"
                                (showParantesesError)="showParantesError($event, groupIndex)"></app-create-formula>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
      <button class="btn btn-primary mt-2" type="button" [ngbTooltip]="'Lägg till graderingsnivå'" (click)="addGraderingsNiva(null)">Lägg till graderingsnivå <fa-icon [icon]="faPlus"></fa-icon></button>

    </div>
  </div>
</form>
