<div class="container-fluid mt-5">
  <div class="row justify-content-center">

    <div class="col-10">
      <!--<div class="col-12 mt-2">
        <ph-alert-message [messageItem]="variableSavedMessage|async" [isSelfClosing]="true"></ph-alert-message>
      </div>-->
      <h1 class="text-center">Hantera ekokardiografivariabler</h1>
      <div class="row">
        <div class="col-sm-6">
          <button class="btn btn-primary mb-2" (click)="openEditShowVariable(0, '')">Lägg till variabel</button>
        </div>
        <div class="col-sm-4">          
        </div>

      </div>
      <!--<h3><span *ngIf="filterVariables=='1'">Aktuella</span><span *ngIf="filterVariables=='2'">Raderade</span><span *ngIf="filterVariables=='3'">Alla</span> variabler</h3>-->
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="4">
              <small>Sök:</small>
              <input type="text" class="form-control" (ngModelChange)="inputChange($event)" [ngModel]="filter"
                     placeholder="Sök / Filtrera...">
            </th>
            
            <th></th>
            <th></th>
            <th>
              <small>Valgrupp:</small>
              <select class="form-select" (ngModelChange)="valgruppChange($event)" [ngModel]="valgruppValue">
                <option [ngValue]="0">Välj valgrupp</option>
                <option *ngFor="let v of valgruppList" [ngValue]="v.value">
                  {{ v.text }}
                </option>
              </select>
            </th>
            <th>
              <small>Format:</small>
              <select class="form-select" (ngModelChange)="formatChange($event)" [ngModel]="formatValue">
                <option [ngValue]="0">Välj format</option>
                <option *ngFor="let f of formatList" [ngValue]="f.value">
                  {{ f.text }}
                </option>
              </select>
            </th>
            <th>
              <small>Enhet:</small>
              <select class="form-select form-control-sm" (ngModelChange)="changeUnit($event)" [ngModel]="unitValue">
                <option [ngValue]="0">Välj Enhet</option>
                <option *ngFor="let u of unitList" [ngValue]="u.value">
                  {{ u.text }}
                </option>
              </select>
            </th>
            <th>
              <small>Status:</small>
              <select class="form-select" (ngModelChange)="statusChange($event)" [ngModel]="statusValue">
                <option [ngValue]="1">Aktiva</option>
                <option [ngValue]="0">Inaktiva</option>
                <option [ngValue]="2">Alla</option>
              </select>
            </th>
            <th></th>
            <th colspan="3"><button class="btn btn-light mt-4" (click)="clearFilter()">Rensa filter</button></th>
          <tr>
        </thead>
        <thead>
          <tr>
            <th>Id</th>
            <th>SysBeskrivning</th>
            <th>Namn</th>
            <th>Kortnamn</th>
            <th>Förk</th>
            <th>Beskrivning</th>
            <!--<th>Grupper</th>-->
            <th>Valgrupp</th>
            <th>Format</th>
            <th>Enhet</th>
            <th>Aktiv</th>
            <th colspan="4"></th>
          </tr>
      <tr *ngIf="showingItems === 0">
        <td class="text-center" colspan="14">
          <i>Inga variabler hittades</i>
        </td>
      </tr>
        </thead>
        <tbody>
          <tr *ngFor="let variable of items">
            <td>{{variable.id}}</td>
            <td>{{variable.sysDescription}}</td>
            <td>{{variable.name}}</td>
            <td>{{variable.shortName}}</td>
            <td>{{variable.short}}</td>
            <td>{{variable.description}}</td>

              <!--<td><ng-container *ngFor="let group of variable.sysEchoVariableSysEchoGroups">{{group.sysEchoGroup?.echoGroup}} </ng-container></td>-->
            <td><ng-container *ngIf="variable.option != null">{{variable.option}}</ng-container></td>
            <td>{{variable.format}}</td>
            <td>{{variable.unitShort}}</td>
            <td>{{variable.active ? 'Aktiv' : 'Inaktiv'}}</td>
              <ng-container *ngIf="variable.active">
                <td><!--<button class="btn btn-info" [ngbTooltip]="'Redigera gradering'" (click)="openEditGradering(variable)"><fa-icon [icon]="faDraftingCompass"></fa-icon></button>--></td>
                <td><button *ngIf="variable.sysInfEchoOptionId == 0 || variable.sysInfEchoOptionId == 7 || variable.sysInfEchoOptionId == 8" [ngClass]="variable.hasGraderingar ? 'btn btn-outline-info' : 'btn btn-info'" [ngbTooltip]="variable.hasGraderingar ? 'Redigera graderingar' : 'Lägg till graderingar'" (click)="openEditFormula(variable.id, variable.name, variable.format)"><fa-icon [icon]="faSquareRootAlt"></fa-icon></button></td>
                <td><button class="btn btn-outline-danger" (click)="openDeleteVariable(variable.id, variable.sysDescription)">Radera</button></td>
                <td><button class="btn btn-info" (click)="openEditShowVariable(variable.id, variable.name)">Redigera</button></td>
              </ng-container>
              <ng-container *ngIf="!variable.active">
                <td colspan="3"></td>
                <td><button class="btn-link btn" (click)="resetVariable(variable)">Återställ</button></td>
                <!--TODO: Skapa återställning-->
              </ng-container>
</tr>
        </tbody>
      </table>
      <!--*ngIf="thePager.totalItems > 10"-->
      <div class="clearfix" style="font-size: 80%; text-align: center" *ngIf="thePager.totalItems > 10">
        <div class="hint-text">Visar <b>{{showingItems}}</b> av <b>{{thePager.totalItems}}</b> variabler</div>
        <div style="display: flex; justify-content: center;">
          <ngb-pagination [collectionSize]="thePager.totalItems" [maxSize]="10" [boundaryLinks]="true" [pageSize]="thePager.maxSize" (pageChange)="pageChanged($event)">
          </ngb-pagination>
        </div>
        <button (click)="topFunction()" id="scrollTopBtn" class="btn btn-outline-primary" title="Gå till toppen"><fa-icon [icon]="faArrowUp"></fa-icon></button>
      </div>
    </div>
  </div>
</div>


