import { ChangeDetectionStrategy, Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { RegEchoVariantDto } from '@model/undersokningsvarianter/reg-echo-variant-dto';
import { UndersokningsVariantService, VariantVariables } from '../../../../core/services/undersokningsvariant.service';
import { SysInfMedicalExaminationType } from '../../../../model/remiss/data-remiss';
import { RemissService } from '../../../../core/services/remiss.service';
import { VariablesService } from '../../../../core/services/variabler.service';
import { EMPTY, Subject, concat, from, of } from 'rxjs';
import { OtherInformationsTexts, PatientDto, PatientRemissUndersokning, PlannedExaminationDto, RemittanceOnlyDto, UndersokningService } from '../../../../core/services/undersokning.service';
import { VariablesByGroupsDto } from '../../../../model/undersokningsvarianter/variables-by-groups-dto';
import { PatientListDto } from '../../../../model/patienter/patient-list-dto';
import { PatientService } from '../../../../core/services/patient.service';
import { faPlus, faTrash, faInfoCircle, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { EchoVariablesDto } from '../../../../model/undersokningsvarianter/echo-variables-dto';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-examination-modal',
  templateUrl: './add-examination-modal.component.html',
  styleUrls: ['./add-examination-modal.component.scss']
})
export class EditExaminationModalComponent implements OnInit {
  //Ikoner
  faPlus = faPlus;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  id: number = 0;
  fromList: boolean = false;
  patientId: number = 0;
  remissId: number = 0;
  uTyp: Observable<SysInfMedicalExaminationType[]>;
  varianter: Observable<RegEchoVariantDto[]> = null!;
  variablesByGroupType2: VariablesByGroupsDto[] = null!;
  plannedExamination: PatientRemissUndersokning;
  valdaVarJson: string = "";
  patienter: Observable<PatientListDto[]> = null!;
  patienterLoading = false;
  patienterInput = new Subject<string>();
  patient: PatientListDto = null!;
  otherInformationsTexts: OtherInformationsTexts[] = [];
  variantsAndVariables: VariantVariables[] = [];
  openAllGroups: boolean = false;
  selectedVariants: number[] = [];
  showVariableTextSpinner: boolean = true;

  constructor(
    public translate: TranslateService,
    private modalRef: NgbActiveModal,
    private rService: RemissService,
    private variantService: UndersokningsVariantService,
    private variableService: VariablesService,
    private uService: UndersokningService,
    private patientservice: PatientService
  ) { }

  private validatorNotZero = [
    Validators.required,
    Validators.pattern(/^(?![0]\d*)/)
  ];
  private _editForm: FormGroup = new FormGroup({
    pnr: new FormControl(""),
    selectedPatient: new FormControl(""),
    langd: new FormControl(""),
    vikt: new FormControl(""),
    epost: new FormControl(""),
    telefon: new FormControl(""),
    namn: new FormControl(""),
    enamn: new FormControl(""),
    kon: new FormControl(""),
    uTyp: new FormControl(""),
    variant: new FormControl(),
    halsoproblem: new FormControl(""),
    patientNewSearch: new FormControl("search"),
    alder: new FormControl(""),
    searchVariables: new FormControl("")
  });
  get editForm(): FormGroup { return this._editForm; }
  get pnr() { return this.editForm.get("pnr"); }
  get invalidPnr() { return this.fieldIsInvalid("pnr"); }
  get errorPnr() { return this.fieldHasError("pnr"); }
  get langd() { return this.editForm.get("langd"); }
  get invalidLangd() { return this.fieldIsInvalid("langd"); }
  get errorLangd() { return this.fieldHasError("langd"); }
  get vikt() { return this.editForm.get("vikt"); }
  get invalidVikt() { return this.fieldIsInvalid("vikt"); }
  get errorVikt() { return this.fieldHasError("vikt"); }
  get enamn() { return this.editForm.get("enamn"); }
  get invalidENamn() { return this.fieldIsInvalid("enamn"); }
  get errorENamn() { return this.fieldHasError("enamn"); }
  get namn() { return this.editForm.get("namn"); }
  get invalidNamn() { return this.fieldIsInvalid("namn"); }
  get errorNamn() { return this.fieldHasError("namn"); }
  get epost() { return this.editForm.get("epost"); }
  get invalidEpost() { return this.fieldIsInvalid("epost"); }
  get errorEpost() { return this.fieldHasError("epost"); }
  get telefon() { return this.editForm.get("telefon"); }
  get invalidTelefon() { return this.fieldIsInvalid("telefon"); }
  get errorTelefon() { return this.fieldHasError("telefon"); }
  get alder() { return this.editForm.get("alder"); }
  get invalidAlder() { return this.fieldIsInvalid("alder"); }
  get errorAlder() { return this.fieldHasError("alder"); }
  get kon() { return this.editForm.get("kon"); }
  //////////
  ///
  private fieldIsInvalid(field: string) {
    return this.editForm.controls[field].invalid && this.editForm.controls[field].touched;
  }
  //////////
  ///
  private fieldHasError(field: string) {
    return this.editForm.controls[field].errors;
  }
  //////////
  ///
  ngOnInit(): void {
    this.fetchVariables();
    this.getListVariantsAndVariables();
    this.setVariants();
    this.loadPatienter();
    this.getTypes();
    if (this.id > 0)
      this.setForm();
  }
  //////////
  ///
  private setForm() {
    if (this.id > 0) {
      this.uService.getPlannedExamination(this.id).subscribe(_ => {
        let variants: Number[] = _.plannedexamination.variantsIds.split(",").map(_ => { return Number(_) });
        this.plannedExamination = _;
        this.plannedExamination.patient = _.patient;
        this.plannedExamination.remiss = _.remiss;
        this.patientId = _.patient.id;
        this.remissId = _.remiss.id;
        this.plannedExamination.plannedexamination = _.plannedexamination;
        this.editForm.patchValue({
          selectPatient: _.patient.id ?? null,
          langd: _.plannedexamination.length,
          vikt: _.plannedexamination.weight,
          epost: _.patient.ePost,
          telefon: _.patient.phone,
          namn: _.patient.name,
          enamn: _.patient.lastname,
          uTyp: _.plannedexamination.sysInfMedicalExaminationTypeId,
          halsoproblem: _.remiss.healthProblems,
          pnr: _.patient.personalIdentityNumber ?? "",
          kon: _.patient.genderId,
          alder: _.patient.age,
          variant: variants
        });

        // Markerar valda variabler från listan med id:n i strängen valdaVarJson.
        if (_.plannedexamination.valdaVarJson) {
          
          this.variableService.getAllEchoVariablesByGroup(2, "", _.plannedexamination.valdaVarJson).subscribe(
            (res: VariablesByGroupsDto[]) => {
              this.variablesByGroupType2 = res.filter(f => f.echoGroupTypeId == 2);
              this.variablesByGroupType2.forEach((group) => {
                group.sysEchoVariables.forEach((variable) => {
                  if (variable.selected)
                    this.editForm.get("var_" + variable.id.toString()).setValue(true);
                  else
                    this.editForm.get("var_" + variable.id.toString()).setValue(false);
                });
              });
              this.showVariableTextSpinner = false;

            });
        }
        // Om det finns texter ska dessa läggas till i listan
        if (_.plannedexamination.otherInformationTexts.length > 0) {
          _.plannedexamination.otherInformationTexts.map((p, i) => {
            this.editForm.addControl("otherText_" + i.toString(), new FormControl(p.description));
            this.otherInformationsTexts.push({
              id: p.id,
              description: p.description
            });
          });
        }
      });
    }
  }
  //////////
  ///
  public onSubmit(gotToExamination = false) {
    //TODO: Lägg till service för redigering
    let echovars: Array<string> = [];
    this.variablesByGroupType2.forEach(group => {
      group.sysEchoVariables.forEach(variable => {
        if (this.editForm.get(["var_" + variable.id.toString()]).value == true && !echovars.includes(variable.id.toString()))
          echovars.push(variable.id.toString());
      });
    });

    let variabler: string = echovars.toString();

    let otherTexts: OtherInformationsTexts[] = [];
      this.otherInformationsTexts.forEach(_ => {
        otherTexts.push({ id: _.id, description: this.editForm.get(["otherText_" + _.id.toString()])?.value });
      });

    const patient: PatientDto = {
      id: this.patientId > 0 ? this.patientId : 0,
      personalIdentityNumber: this.editForm.get("pnr")?.value ?? "",
      name: this.editForm.get("namn")?.value ?? "",
      lastname: this.editForm.get("enamn")?.value ?? "",
      ePost: this.editForm.get("epost")?.value ?? "",
      phone: this.editForm.get("telefon")?.value ?? "",
      genderId: this.editForm.get("kon")?.value ?? "",
      age: this.editForm.get("alder")?.value ?? ""
    };

    console.log("remissid" + this.remissId + "patientId" + this.patientId + "UndersökningsId" + this.id);
    const remiss: RemittanceOnlyDto = {
      id: this.remissId > 0 ? this.remissId : 0,
      patientId: this.id > 0 ? this.plannedExamination.remiss.patientId : 0,
      remittanceClinicId: this.id > 0 ? this.plannedExamination.remiss.remittanceClinicId : 1,
      remittanceDoctorId: this.id > 0 ? this.plannedExamination.remiss.remittanceDoctorId : 1,
      active: this.id > 0 ? this.plannedExamination.remiss.active : true,
      remittanceStatusId: this.id > 0 ? this.plannedExamination.remiss.remittanceStatusId : 1,
      healthProblems: this.editForm.get("halsoproblem")?.value
    };
    const undersokning: PlannedExaminationDto = {
      id: this.id > 0 ? this.plannedExamination.plannedexamination.id : 0,
      remittanceClinicId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceClinicId : 0,
      remittanceDoctorId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceDoctorId : 0,
      remittanceId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceId : 0,
      weight: this.editForm.get("vikt")?.value ?? "",
      length: this.editForm.get("langd")?.value ?? "",
      active: this.id > 0 ? this.plannedExamination.plannedexamination.active : true,
      sysInfMedicalExaminationTypeId: this.editForm.get("uTyp")?.value ?? null,
      valdaVarJson: variabler,
      otherInformationTexts: otherTexts,
      variantsIds: this.editForm.get("variant")?.value.toString() ?? ""
    };
    const helaundersokning: PatientRemissUndersokning = {
      patient: patient,
      remiss: remiss,
      plannedexamination: undersokning
    }
    let examinationId;
    console.table(helaundersokning);
    this.uService.savePlannedMedicalExamination(this.id, helaundersokning).subscribe({
      next: (id) => {
        examinationId = id;
        this.modalRef.close();
        
      },
      error: (errorResponse) => {
        //  this.errorMessage = errorResponse;
      },
      complete: () => {
        if (gotToExamination) {
          console.log("Id: " + examinationId);
          window.location.href = 'echo-testbadd/' + examinationId;
        }
      }
    });
  }
  //////////
  ///
  private variableInputChangeTimer: ReturnType<typeof setTimeout> | null = null;
  variableInputChange(event: string) {
    const source = from(this.variablesByGroupType2.flatMap(_ => _.sysEchoVariables));
    if (event.length > 0 && event.length < 3)
      return;


    if (this.variableInputChangeTimer != null)
      clearTimeout(this.variableInputChangeTimer);

    this.variableInputChangeTimer = setTimeout(() => {
      const tosend = source.pipe(filter(v => v.shortName.includes(event)));
      tosend.subscribe(_ => {
        //TODO: Fortsätt med sökfiltreringen här.
        this.variablesByGroupType2.map(v => v.sysEchoVariables.filter(f => f.id != _.id)[0].show = false)
      });
      this.variableInputChangeTimer = null;
    }, 1000);
    return;
  }

  //////////
  ///
  getTypes() {
    this.uTyp = this.rService.undersokningstyperList$.pipe(catchError(err => {
      return EMPTY;
    }));
  }
  //////////
  ///
  onCancel(): void {
    this.modalRef.dismiss();
  }
  //////////
  ///
  changeTyp() {
    this.editForm.get("variantId")?.reset();
    this.setVariants();
    //if (!(this.id > 0))
    this.fetchVariables();
  }
  //////////
  ///

  setVariants() {
    this.varianter = this.variantService.examinationTypesList$.pipe(catchError(err => {
      return EMPTY;
    }));
  }
  //////////
  ///
  fetchVariables() {
    //Ladda bägge listvyerna direkt och toggla sedan mellan dessa?
    this.variableService.getAllEchoVariablesByGroup(2).subscribe(
      (res: VariablesByGroupsDto[]) => {
        this.variablesByGroupType2 = res.filter(f => f.echoGroupTypeId == 2);
        this.variablesByGroupType2.forEach(group => {
          group.sysEchoVariables.forEach((variable) => {
            this.editForm.addControl("var_" + variable.id.toString(), new FormControl(false));
          });
        });
      });

    //(([count, records]) => {
    //  this.thePager.totalItems = count;
    //  this.items = records;
    //  this.showingItems = this.items.length;
    //  this.emptyFill = new Array(20 - this.showingItems);
    //});
  }
  //Hämtar en lista med alla varianter och dess variabler.
  getListVariantsAndVariables() {
    this.variantService.getAllEchoVariantsVariables().subscribe((_) => {
      _.map(v => {
        let vIds: number[] = [];
        v.valdaVarJson.split(",").map(i => {
          vIds.push(Number(i));
        })
        this.variantsAndVariables.push({ variantId: Number(v.id), variableIds: vIds });
      })
    });
  }
  //////////
  ///
  openCloseAllGroups(openAll: boolean) {
    this.openAllGroups = openAll;
    this.variablesByGroupType2.forEach(_ => _.open = openAll);
  }
  //////////
  ///
  selectAllInGroup(grupp: VariablesByGroupsDto, toggle: boolean) {
    grupp.toggleAll = toggle;

    grupp.sysEchoVariables.forEach((variable) => {
      this.editForm.get("var_" + variable.id.toString()).setValue(toggle);
    });
    //Sätt alla som markerade i parentesen vid rubriken
    if (grupp.toggleAll) {
      grupp.selectedVCount = grupp.sysEchoVariables.length
    }
    if (!grupp.toggleAll) {
      grupp.selectedVCount = 0
    }
  }
  //////////
  ///
  showAddedAndRemovedVariables(): string {
    let currentSelectedVarianter: number[] = this.editForm.get("variant")?.value ?? [];
    let addedVariables: number[] = [];
    let missingVariables: number[] = [];
    let selectedVariables: number[] = [];
    let variantsVariables: number[] = [];
    //Kolla vilka variabler som är markerade
    this.variablesByGroupType2?.forEach(_ => _.sysEchoVariables.forEach(va => {
      let variableValue = this.editForm.get("var_" + va.id)?.value;
      if (variableValue == true)
        selectedVariables.push(va.id);
    }));
    //Valda variantes variabler
    currentSelectedVarianter.forEach(_ => {
      this.variantsAndVariables.filter(v => v.variantId == _).map(v => {
        v.variableIds.forEach(va => {
          if (!variantsVariables.includes(va))
            variantsVariables.push(va);
        });
      });
    })
    //Jämför listorna
    addedVariables = selectedVariables.filter(_ => !variantsVariables.includes(_));
    missingVariables = variantsVariables.filter(_ => !selectedVariables.includes(_));

    let addedVariablesDesc: string[] = [];
    let missingVariablesDesc: string[] = [];
   
    missingVariables.forEach(_ => {
      this.variablesByGroupType2.map(g => {
        g.sysEchoVariables.filter(v => v.id == _).map(v => missingVariablesDesc.push(v.shortName))
      });
    });
    addedVariables.forEach(_ => {
      this.variablesByGroupType2.map(g => {
        g.sysEchoVariables.filter(v => v.id == _).map(v => addedVariablesDesc.push(v.shortName))
      });
    });
    let toReturn = "";
    if (addedVariables.length > 0)
      toReturn += "Tillagda: " + addedVariablesDesc + ". ";
    if (missingVariables.length > 0)
      toReturn += "Saknas: " + missingVariablesDesc + ". ";
    return toReturn; 
  }
  //////////
  ///
  changeSelectedVariants() {
    let currentSelected: number[] = this.editForm.get("variant")?.value;
    let added = currentSelected.filter(item => this.selectedVariants.indexOf(item) < 0);
    let missing: number[] = this.selectedVariants.filter(item => currentSelected.indexOf(item) < 0);

    //Kolla så att man inte checkar ur någon variabel som är på annan variant.  
    console.log("Ska bort: " + missing + "Tillagd: " + added);

    //Kolla här innan nu om det är några som inte ska tas bort för att dom finns i andra varianter.
    let doNotRemove: number[] = [];
    //Kolla så att variabeln inte finns på någon av de markerade varianterna. Då ska den inte checkas ur.
    let selectedVariants = this.variantsAndVariables.filter(f => currentSelected.includes(f.variantId));
    if (missing.length > 0)
      selectedVariants.map(_ => {
        //Blir detta långsamt?
        _.variableIds.filter(item => missing.indexOf(item) < 0).map(d => doNotRemove.push(d));
      //  doNotRemove.push(_.variableIds.find(f => missing.includes(f)));
      });
    //Loopa variantens variabler som ska tas bort och checka ur.
    missing.forEach(_ => this.variantsAndVariables.filter(v => v.variantId == _).map(v => v.variableIds.forEach(va => {
      // Om variabeln finns i en annan markerad variant ska den inte tas bort.
      if (!doNotRemove.includes(va))
        this.editForm.get("var_" + va)?.patchValue(false);
    })))
    //Loopa tillagda varianter och checka i.
    added.forEach(_ => this.variantsAndVariables.filter(v => v.variantId == _).map(v => v.variableIds.forEach(va => {
      this.editForm.get("var_" + va)?.patchValue(true);
    })))

    this.selectedVariants = this.editForm.get("variant")?.value;
    this.showVariableTextSpinner = false;
  }
  //////////
  ///
  countSelectedInGroups(groupId: number) {
    //Här skulle vi kunna skicka in påde grupp och grupptyp om vi vill visa hur många som är markerade?
    let gCount = 0;
    let grupp = this.variablesByGroupType2.find(g => g.id == groupId);
      grupp?.sysEchoVariables.forEach(v => {
        //För varje variabel som är markerad i gruppen
        if (this.editForm.get("var_" + v.id)?.value == true) {
          gCount += 1;
        }
      });
    let countToReturn = "";
    //Om det är 0 markerad i gruppen ska inget visas.
    if (gCount > 0)
      countToReturn = "(" + gCount + ")";
    return countToReturn;
  }
  //////////
  ///
  personnummerChangeInput(pnr: string) {

    if (pnr.length >= 10) {
      this.patientservice.getPatienter(pnr)
        .subscribe(patient => {
          this.selectPatient(patient[0]);
        });
    }
    else
      return;
  }
  //////////
  ///
  selectPatient(p: PatientListDto) {

    this.patientId = p.id;
    this.pnr?.setValue(p.personalIdentityNumber);
    this.enamn?.setValue(p.lastname);
    this.namn?.setValue(p.name);
    this.telefon?.setValue(p.phone);
    this.epost?.setValue(p.ePost);
    this.kon?.setValue(p.genderId);
    this.alder?.setValue(p.age);
  }
  //////////
  ///
  private loadPatienter() {
    this.patienter = concat(
      of([]), // default items
      this.patienterInput.pipe(
        distinctUntilChanged(),
        tap(() => this.patienterLoading = true),
        switchMap(term => term != null && term.length < 3 ? of([]) : this.patientservice.getPatienter(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.patienterLoading = false)
        ))
      )
    );
  }
  //////////
  ///
  addOtherText() {
    let nextIndex = this.otherInformationsTexts.length;
    this.otherInformationsTexts.push({ id: nextIndex, description: "" });
    this.editForm.addControl("otherText_" + nextIndex.toString(), new FormControl());
  }
  //////////
  ///
  removeOtherText(index: number) {
    this.editForm.removeControl("otherText_" + index);
    let indexToRemove = this.otherInformationsTexts.findIndex(_ => _.id == index);
    this.otherInformationsTexts.splice(indexToRemove, 1);
  }
  //////////
  ///
  startExamination() {
    let gotToExamination: boolean = true;
    this.onSubmit(gotToExamination);
  }
}
@Injectable({
  providedIn: "any"
})
export class EditExaminationModalService {
  constructor(private modalService: NgbModal) { }

  public show(id: number, fromList: boolean = false): Observable<PlannedExaminationDto> {
    const modalRef = this.modalService.open(EditExaminationModalComponent, { backdrop: "static", size: "xl" });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.fromList = fromList;
    return modalRef.closed;
  }
}


