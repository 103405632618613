import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { ViewEchoVariableDto } from '../../../../model/undersokningsvarianter/view-echo-variable-dto';
import { SelectedMeasuremenMethod } from '../../../settings/components/add-echovariable-modal/add-echovariable-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectLista, Typselectlist } from '../../echo-testbadd/echo-testbadd.component';
import { VariablesService } from '../../../../core/services/variabler.service';
import { faBars, faAtom } from "@fortawesome/free-solid-svg-icons";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'edit-value-examination',
  templateUrl: './edit-value-examination.component.html',
  styleUrls: ['./edit-value-examination.component.scss']
})
export class EditValueExaminationComponent implements OnInit {
  constructor(private readonly element: ElementRef
  ) { }
  //Ikoner
  faBars = faBars;
  faAtom = faAtom;
  @Input() variable: ViewEchoVariableDto;
  @Input() currentValue: string;
  @Input() variableId: number;
  @Input() style: number; 
  @Input() valdGrupp: number;
  @Input() showEditVariable: boolean;
  //@Input() gindex: number; 
  @Input() vindex: number;
  @Input() typeSelectList: SelectLista[];
  //@Output() private onFormGroupChange = new EventEmitter<any>();
  @Output() private variableValuesChanged = new EventEmitter<VariableAndValue>();
  @Output() private showEdit = new EventEmitter<{ show: boolean, variabelId: number }>();
  @Output() private openEditInfoVariabel = new EventEmitter<boolean>();
  @Output() private tabToNextVariable = new EventEmitter<{ vindex: number, style: number, id: number }>();
  @Output() private updateVariables = new EventEmitter<number>();

  @ViewChild("content") modalHtml;
  private _variableForm: FormGroup = new FormGroup({
    valgrupp: new FormControl(null),
    var_text: new FormControl(""),
    currentValue: new FormControl(""),
    currentValueSelect: new FormControl("")
  });
  get variableForm(): FormGroup { return this._variableForm; }
  get cValue() { return this._variableForm.get('currentValue'); }
  get valgrupp() { return this._variableForm.get('valgrupp'); }
  get currentValueSelect() { return this._variableForm.get('currentValueSelect'); }

  ngOnInit(): void {
    this.variable.currentValue = this.currentValue;
    this.variable.showEdit = this.showEditVariable;
    if (this.variable.measurementMethods)
      this.variable.measurementMethods = this.variable.measurementMethods.sort((a, b) => a.sortorder - b.sortorder);
    this.setForm();
    this.variableValuesChanged.emit({ variable: this.variable, value: this.variable.currentValue });
    //Om det är ett indexerat värde ska den inte den kunna ändras
    if (this.variable.indexedOnBSA) {
      this.variableForm.get("currentValue").disable();
    }
    //this.valuesChanged();
  }
  setForm() {
    this.variableForm.get("currentValue")?.patchValue(this.variable.currentValue);
    this.variableForm.get("currentValue")?.setValidators([Validators.max(this.variable.impossibleValueMax), Validators.min(this.variable.impossibleValueMin)]);
    this.variableForm.get("valgrupp")?.patchValue(this.variable.currentValue);

    //Sätt selectvärde om det är selectlista
    //this.variableForm.get("valgrupp")?.patchValue(this.variable.currentValue);

    this.variableForm.addControl("var_text", new FormControl());
    this.variable.measurementMethods?.forEach(m => {
      this.variableForm.addControl("metod" + m.id, new FormControl(m.value));
      //Sätter prio 1 som förval.
      this.variableForm.addControl("metodRadio", new FormControl(m.sortorder == 0 ? m.id : null));
      if (m.sortorder == 0)
        m.selected = true;
      if (m.selected) {
        this.variableForm.get("currentValue").setValue(m.value);
        this.variable.currentValue = m.value
        return;
      }
    });
    //Sätt vilken som ska vara vald i select-listan 
    if (this.variable.valueTypeId > 0 && this.variable.valueTypeId < 7)
      this.variableForm.get("currentValueSelect")?.patchValue(this.variable.currentValue);
  } 
  variableChangeInput(value: string, style: number, metodId?: number) {
    if (this.variable.measurementMethods.length > 0) {
      let currentSelectedMethod = this.variableForm.get("metodRadio").value;
      //Sätter den metod som är markerad som currentValue.
      let selectedMethodValue = this.variableForm.get("metod" + currentSelectedMethod)?.value ?? "";
      this.variable.currentValue = selectedMethodValue;

      //Sätter värdet i currenValue - input
      this.variable.measurementMethods.filter(f => f.id == metodId)[0].selected = true;
      this.variable.measurementMethods.map(_ => {
        _.value = this.variableForm.get("metod" + _.id)?.value ?? "";
        if (this.variableForm.get("metodRadio").value == _.id) {
          _.selected = true;
          this.variableForm.get("currentValue").setValue(_.value);
        }
        else
          _.selected = false;
      });   
    } 
    //Om variabeln inte har några metoder
    else {
      this.variable.currentValue = this.variableForm.get("currentValue")?.value ?? "";
      this.showHideEdit(false);
    }
    this.variableForm.get("currentValue")?.patchValue(this.variable.currentValue);
    if (this.variable.valueTypeId > 0 && this.variable.valueTypeId < 7)
      this.variableForm.get("valgrupp")?.patchValue(this.currentValue);
    this.variableValuesChanged.emit({ variable: this.variable, value: this.variable.currentValue });
    //this.tabToNextVariable.emit({ vindex: this.vindex, style: style, id: this.variableId });
    
  }
  showHideEdit(show: boolean) {
    this.variableForm.get("valgrupp")?.patchValue(this.variable.currentValue);

    console.log("VariabelId" + this.variableId + "Show" + show + "Valuetyp" + this.variable.valueTypeId);
    this.variable.showEdit = show;
    
    this.variableValuesChanged.emit({ variable: this.variable, value: this.variable.currentValue });
    this.showEdit.emit({ show: show, variabelId: this.variableId });
    //TODO: Fortsätt här med att sätta fokus på input i tabell och lista
  }
  //////////
  ///
  onChangeValgrupp(variable: ViewEchoVariableDto, event: any, style: number) {
    //Om något val är satt
    if (event.target.value != null) {
      variable.currentValue = event.target[event.target.selectedIndex].text.split(' |', 1).toString();
      variable.showEdit = false;
      let splitText = event.target[event.target.selectedIndex].text.split('|');
      splitText[1] = splitText[1].toLowerCase();
      variable.graderingText = variable.shortName + " är " + splitText[1];
      this.variableValuesChanged.emit({ variable: this.variable, value: this.variable.currentValue ?? "" });
      //this.tabToNextVariable.emit({ vindex: this.vindex, style: style, id: this.variableId });
    }
  }
  //Öppna redigering för information om variabeln
  openEditVariabel() {
    this.openEditInfoVariabel.emit(true);
  }
  //Välj selectval med tangentbordet (Id). Hur blir det om det är mer än 9 val?
  onKeyPressedOption(event: KeyboardEvent) {
    if (event.keyCode - 97 >= 0 && event.keyCode - 97 <= 2) {
      this.typeSelectList = this.typeSelectList[event.keyCode - 97].value;
      //this.tabToNextVariable.emit({ vindex: this.vindex, style: this.style, id: this.variableId });
    }
  }
}

export class VariableAndValue
{ variable?: ViewEchoVariableDto; value?: string; }
