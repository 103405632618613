  <div class="modal-header">
    <h5 class="modal-title">Redigera gradering för {{variableName}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
  </div>
  <form [formGroup]="editVariableFormulaForm" (ngSubmit)="submitForm()">
    <div class="modal-body">
      <div class="container-fluid">
        <div class="jumbotron col-sm-12 p-2 m-0 bg-inverse">
          <div class="row mt-3">
            <div class="col-10">
              <label for="metod" class="form-label">Intervalltyp: </label>
              <select class="form-select" id="metod" formControlName="intervalltyp" (change)="changeIntervalltypSelect()">
                <option value="0">Välj intervalltyp</option>
                <ng-container *ngFor="let iTyp of intervalltyp">
                  <option [value]="iTyp.id">{{iTyp.beskrivning}}</option>
                </ng-container>
              </select>
            </div>
            <div class="col-2 py-0">
              <button type="button" class="btn btn-sm btn-outline-primary w-100 h-100" (click)="clearForm()">Nollställ formulär</button>
            </div>
          </div>
              <div class="form-group mt-3" *ngIf="editVariableFormulaForm.value.intervalltyp == 1">
                <label for="radioTypeSamma" class="col-form-label">Välj typ: </label><br>
                <input type="radio" id="radioTypeOlika" [value]="1" formControlName="kontyp" (change)="konTypChange()">
                <label for="radioTypeOlika" class="px-2">Olika formler för man och kvinna</label><br>
                <input type="radio" id="radioTypeSamma" [value]="0" formControlName="kontyp" (change)="konTypChange()">
                <label for="radioTypeSamma" class="px-2">Samma för man och kvinna</label><br>
              </div>
          <div class="form-group mt-3" *ngIf="editVariableFormulaForm.value.intervalltyp != 0">
            <label for="referens">URL till referens på graderingar: </label>
            <input type="text" class="form-control" id="referens" placeholder="Url referens" formControlName="referens">
          </div>
          <div *ngIf="editVariableFormulaForm.value.intervalltyp == 1" class="row">
            <div class="col-md-12">
              <button class="btn btn-sm btn-default float-end mb-1" type="button" (click)="toggleGrunddata()">
                Fyll i grunddata för att testa beräkningar <fa-icon [icon]="grunddataOpen ? faChevronUp: faChevronDown"></fa-icon>
              </button>
            </div>
            <div class="col-md-12" *ngIf="grunddataOpen">
              <div class="card">
                <div class="card-header">
                  Grunddata
                </div>
                <div class="card-body">
                  <div class="alert alert-warning" *ngIf="message != ''">{{message}}</div>
                  <div class="form-group">
                    <label for="alder">Ålder</label>
                    <input type="text" class="form-control" id="alder" placeholder="Ålder" formControlName="alder" (change)="updateGrunddata()">
                  </div>
                  <div class="form-group">
                    <label for="vikt">Vikt</label>
                    <input type="text" class="form-control" id="vikt" placeholder="Vikt" formControlName="vikt" (change)="updateGrunddata()">
                  </div>
                  <div class="form-group">
                    <label for="langd">Längd</label>
                    <input type="text" class="form-control" id="langd" placeholder="Längd" formControlName="langd" (change)="updateGrunddata()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" *ngFor="let group of formular">
              <!--Enkelt intervall för man och kvinna separat-->
              <ng-container *ngIf="group.typId == 1 && editVariableFormulaForm.value.intervalltyp == 2">
                <mat-tab-group *ngIf="group.tabGroup" (selectedTabChange)="onTabChanged($event)" class="w-100">
                  <mat-tab *ngFor="let formel of group.group">
                    <ng-template mat-tab-label>
                      {{formel.header}}
                    </ng-template>
                    <app-gradering-formel-echovariable-vers3 [intervallTyp]="2" [refTypId]="group.typId" [format]="cVariableFormat" (grunddataOpen)="showHideGrunddata($event)" [konTyp]="editVariableFormulaForm.value.kontyp" (allFormulaData)="getFormulaData($event, formel.konId)" [cVariable]="cVariable" [group]="formel" [grunddata]="grunddata" [formChanged]="formChanged" (message)="showMessage($event)" [kon]="formel.konId"></app-gradering-formel-echovariable-vers3>
                  </mat-tab>
                </mat-tab-group>
              </ng-container>
              <!--Formelintervall för man och kvinna separat-->
              <ng-container *ngIf="editVariableFormulaForm.value.intervalltyp == 1 && group.typId == 1 && editVariableFormulaForm.value.kontyp == 1">
                <mat-tab-group *ngIf="group.tabGroup" (selectedTabChange)="onTabChanged($event)" class="w-100">
                  <mat-tab *ngFor="let formel of group.group">
                    <ng-template mat-tab-label>
                      {{formel.header}}
                    </ng-template>
                    <app-gradering-formel-echovariable-vers3 [refTypId]="group.typId" [format]="cVariableFormat" (grunddataOpen)="showHideGrunddata($event)" [intervallTyp]="1" [konTyp]="editVariableFormulaForm.value.kontyp" (allFormulaData)="getFormulaData($event, formel.konId)" [cVariable]="cVariable" [group]="formel" [grunddata]="grunddata" [formChanged]="formChanged" (message)="showMessage($event)" [kon]="formel.konId"></app-gradering-formel-echovariable-vers3>
                  </mat-tab>
                </mat-tab-group>
              </ng-container>
              <!--Formelintervall för man och kvinna tillsammans-->
              <ng-container *ngIf="!group.tabGroup && editVariableFormulaForm.value.intervalltyp == 1 && group.typId == 1 && editVariableFormulaForm.value.kontyp == 0">
                <ng-container *ngFor="let formel of group.group">
                  <app-gradering-formel-echovariable-vers3 [refTypId]="group.typId" [format]="cVariableFormat" (grunddataOpen)="showHideGrunddata($event)" [intervallTyp]="1" [formChanged]="formChanged" [konTyp]="editVariableFormulaForm.value.kontyp" (allFormulaData)="getFormulaData($event, formel.konId)" [cVariable]="cVariable" [group]="formel" [grunddata]="grunddata" (message)="showMessage($event)" [kon]="formel.konId"></app-gradering-formel-echovariable-vers3>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="alert alert-danger" *ngIf="errorMessage != ''">{{errorMessage}}</div>
        </div>
      </div>    
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info cancel" (click)="onCancel()">{{'common.close' | translate}}</button>
      <button type="submit" class="btn btn-lg btn-success">{{'common.save' | translate}}</button>
    </div>
  </form>
