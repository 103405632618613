import { Injectable, Inject } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalculationService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }
  private tecken: Tecken[] = [{ id: 1, description: "=", selectedControlIndex: null, selected: false }, { id: 2, description: "<", selectedControlIndex: null, selected: false }, { id: 5, description: ">", selectedControlIndex: null, selected: false }];
  private kon: InfKon[] = [{ id: 0, beskrivning: "Samma för man och kvinna" }, { id: 1, beskrivning: "Kvinna" }, { id: 2, beskrivning: "Man" }];

  public getTecken(): Observable<SysInfComparisonSymbol[]> {
    return this.http.get<SysInfComparisonSymbol[]>(this.baseUrl + 'api/calculation/comparisonSymbols');
  }

  filterTecken(id: number): string {
    let tecken = this.tecken.find(_ => _.id === id)?.description
    return tecken;
  }
  filterKon(id: number): string {
    let kon = this.kon.find(_ => _.id === id).beskrivning
    return kon;
  }
  public beraknaBsa(grunddata: GrunddataDto): Observable<any> {
    return this.http.post<any>(this.baseUrl + "api/calculation/calculatebsa", grunddata);
  }
  public getCalculatedFormulaLinnea(formel: any, grunddata?: GrunddataDto): Observable<any> {
    console.table(formel);
    console.table(grunddata);
    return this.http.post<[Formel, GrunddataDto]>(this.baseUrl + "api/calculation/FormulaCalculationLinnea", { formel: formel, grunddata: grunddata });
  }
  public calculateoverunder(formel: any): Observable<any> {
    console.table(formel);
    return this.http.post<Formel>(this.baseUrl + "api/calculation/calculateoverunder", { formel: formel});
  }
  
  public gradera(formler: Formel[], grunddata: GrunddataDto, format: string = "###", isFlode: boolean, ulValue: string): Observable<any> {
    console.log("Skicka denna formel");
    console.table(formler);
    return this.http.post<[Formel[], string, GrunddataDto, boolean, string]>(this.baseUrl + "api/calculation/gradera", { formler: formler, format: format, grunddata: grunddata, isFlode: isFlode, ulValue: ulValue });
  }

  public getVariabelGraderingar(id: number): Observable<VariabelFormler> {
    return this.http.get<VariabelFormler>(this.baseUrl + `api/calculation/variabelgraderingar/${id}`);
  }
  public getCalculatedFormulaSelma(formel: any, grunddata: GrunddataDto): Observable<any> {
    console.table(formel);
    console.table(grunddata);
    return this.http.post<[Formel, GrunddataDto]>(this.baseUrl + "api/calculation/FormulaCalculationSelma", { formel: formel, grunddata: grunddata });
  }

  public editVariableFormula(id: number, jsonData: VariabelFormler): Observable<any> {
    if (id != null && id !== 0) {
      return this.http.patch<any>(this.baseUrl + `api/echovariables/AddJson/${id}`, jsonData);
    }
  }
}
//export class Formel {
//  formel: string;
//  //unitId: number;
//  format: string;
//}
export class GrunddataDto {
  grunddataId?: number;
  metodId?: number;
  längd?: string | "";
  vikt?: string | "";
  ålder?: string | "";
  kön?: string;
  bsaMetod?: number;
  könId?: number;
}
export class FormelTecken {
  id: number;
  beskrivning: string;
  tillFormeln?: string;
  utanKonstant?: string;
}
export class IntervallTyp {
  id: number;
  beskrivning: string;
}
export type FormTecken = {
  konId: number;
  intervalltyp: number;
  graderingar: Gradering[];
}
//export type Tecken = {
//  id: number;
//  description: string;
//  selectedControlIndex?: number;
//  selected?: boolean | false;
//}
export type Tecken = {
  id: number;
  description: string;
  selectedControlIndex?: number;
  selected?: boolean | false;
}
//export class BeräkningsMetoderLinnea {
//  metodId: number;
//  metodNamn: string = "";
//  beskrivning?: string;
//  konstanter: Konstanter[];
//  metodFormler: MetodFormler[]
//}
//export class MetodFormler {
//  graderingsTyp: Graderingstyp;
//  formelBeskrivning: string;
//  formel?: string;
//  formelMedKonst: string;
//  graderingstypNamn?: string;
//}
//export class SaveBerakningsMetodLinnea {
//  metodId: number;
//  metodNamn: string;
//  beskrivning?: string;
//  formler?: SaveMetodFormler[];
//  //  konstanter?: SaveKonstanter[];
//}
//export class SaveMetodFormler {
//  kon?: Kon;
//  graderingsTyp?: Graderingstyp;
//  formelBeskrivning?: string;
//  formelInkKonst?: string;
//  formel?: string;
//  formelInnanKonst?: string;
//  konstanter?: SaveKonstanter[];
//  graderingstypNamn?: string;
//}
//export class Konstanter {
//  id: number;
//  beskrivning: string;
//  varde?: string;
//}
//export class SaveKonstanter {
//  id: number;
//  beskrivning: string;
//  varde?: number;
//}

export class RefTyp {
  id: number;
  beskrivning: string;
}
export class VariabelFormler {
  intervalltyp: number;
  kontyp?: number; //Samma = 0, Olika = 1
  format?: string | "";
  formler?: Formel[];
}
export class Formel {
  id?: number;
  intervalltyp?: number;
  kon?: number | 0;
  konBeskrivning?: string | "";
  formel?: string | "";
  format?: string | "";
  graderingsText?: string | "";
  graderingsTecken?: string | "";
  visaEjISvar?: boolean | false;
  beraknat?: string | "";
  startValue?: string | "";
  deleted?: boolean | false;
  isMedelvarde?: boolean | false;
  showErrorMessageParanteses?: boolean | false;
  hideInSvar?: boolean | false;
  visaVarningssymbol?: boolean | false;
  normalOmrade?: boolean | false;
}

export class ToggleListItem {
  constructor(
    public styleClass: string,
    public text: string,
    public value: string
  ) { }
}
export class TabGroup {
  tabGroup: boolean;
  typId: number;
  group: Formular[];
}
export class Formular {
  header: string;
  konId: number;
  formcontroller?: VariabelFormularFormController[];
}
export class VariabelFormularFormController {
  id?: number;
  intervallTyp: number;
  beskrivning?: string;
  reftypId?: number;
  formel?: string | "";
  graderingsTyp?: number | null;
  graderingsGrupp?: number | null;
  graderingsText?: string | "";
  graderingsTecken?: string | "";
  //delete?: boolean | false;
  visaEjISvar: boolean | false;
  konId?: number;
  startValue?: string | "";
  deleted?: boolean | false;
  hide?: boolean | false;
  isMedelvarde?: boolean | false;
  slutValue?: string | "";
  underOverUtlatande?: string | "";
  underOverVarde?: string | "";
  order?: number | 0;
  visaVarningssymbol?: boolean | false;
  normalOmrade?: boolean | false;
}
export class InfKon {
  id: number;
  beskrivning: string;
}
export enum Kon {
  Samma = 0,
  Kvinna = 1,
  Man = 2
}
export enum Graderingstyp {
  Normal = 0,
  Nedre = 1,
  Övre = 2
}
export class VariabelGradering {
  id: number;
  konGradering: KonGradering;
}
export class KonGradering {
  konId: number;
  gradering: Gradering[];
}
export class Gradering {
  id: number;
  comparisonSymbol: string;
  description?: string;
  tooltip?: string | "";
  intervalltyp?: number;
  shortDesc?: string | "";
  graderingsGrupp?: number | null;
  klarText?: string | "";
  hide?: boolean | false;
  hasCheckbox?: boolean | false;
  isMedelvarde?: boolean | false;
  deleted?: boolean | false;
  hasUnderOverVarde?: boolean | false;
  underOverVarde?: string | "";
  hasVarde?: boolean | true;
  uoklarText?: string | "";
  //selected?: boolean;
  //selectedControlIndex?: number;
}

export class SysInfComparisonSymbol {
  id: number;
  comparisonSymbol: string;
  description?: string;
}
