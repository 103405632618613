import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, forkJoin, Observable } from 'rxjs';
// TODO: Ta ev. bort Variabler (använder Listview- och View- EchoVariable istället)
import { EchoVariablesDto } from '@model/undersokningsvarianter/echo-variables-dto';
import { VariablesByGroupsDto } from '@model/undersokningsvarianter/variables-by-groups-dto';
import { SysEchoGroupDto } from '@model/undersokningsvarianter/sys-echo-group-dto';
import { SysInfUnitDto } from '@model/undersokningsvarianter/sys-inf-unit-dto';
import { SysInfFormatDto } from '@model/undersokningsvarianter/sys-inf-format-dto';
//import { ListviewSysEchoVariableDto } from '@model/undersokningsvarianter/listview-sys-echo-variable-dto';
import { SysInfEchoValueTypeDto } from '@model/undersokningsvarianter/sys-inf-echo-value-type-dto';
import { SysInfEchoValueTypeListDto } from '@model/undersokningsvarianter/sys-inf-echo-value-type-list-dto';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { GrunddataDto } from '@model/beräkningar/grunddata-dto';
import { ListviewSysEchoVariableDto } from '../../model/undersokningsvarianter/listview-sys-echo-variable-dto';
import { SysInfEchoMeasuremenMethodDto } from '../../model/inf/sys-inf-measuremen-method-dto';
import { ViewEchoVariableDto } from '../../model/undersokningsvarianter/view-echo-variable-dto';


@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  countTimer: ReturnType<typeof setTimeout> | null = null;
  totalItems: Observable<number> | null = null;
  lastCount = "";


  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public getAllaEchoVariables(): Observable<ListviewSysEchoVariableDto[]> {
    return this.http.get<ListviewSysEchoVariableDto[]>(this.baseUrl + 'api/echovariables');
  }
  getAllaEchoVariablesList(page: number, itemsPerPage: number, statusValue: number, unitValue: number, formatValue: number, valgruppValue: number, filter: string): Observable<[number, ListviewSysEchoVariableDto[]]> {
    const ofs = (page - 1) * itemsPerPage;
    const cnt = itemsPerPage;

    let query = `${this.baseUrl}api/echovariables/list?offset=${ofs}&count=${cnt}`;
    let count = `${this.baseUrl}api/echovariables/count`;
    if (filter.length >= 2) {
      query += `&filter=¦${filter}¦`;
      count += `?filter=¦${filter}¦`;
    }
    else {
      count += "?filter=";
    }

    if (statusValue >= 0) {
      query += `&statusValue=${statusValue}`;
      count += `&statusValue=${statusValue}`;
    }
    if (formatValue > 0) {
      query += `&formatValue=${formatValue}`;
      count += `&formatValue=${formatValue}`;
    }
    if (valgruppValue > 0) {
      query += `&valgruppValue=${valgruppValue}`;
      count += `&valgruppValue=${valgruppValue}`;
    }
    count = count.replace("/&", "?");
    if (unitValue > 0) {
      query += `&unitValue=${unitValue}`;
      count += `&unitValue=${unitValue}`;
    }
    return forkJoin([this.getCount(count), this.http.get<ListviewSysEchoVariableDto[]>(query)]);       
  }
  getNotIndexedVariablesList(filter: string, excludeVariableId: number = 0): Observable<ListviewSysEchoVariableDto[]> {
    let query = `${this.baseUrl}api/echovariables/listNotIndexed?filter=¦${filter}¦&excludeVariableId=${excludeVariableId}&excludeIndexedValues=${true}`;
    return this.http.get<ListviewSysEchoVariableDto[]>(query);
  }
  //public getAllaEchoVariables(): Observable<EchoVariablesDto[]> {
  //  return this.http.get<EchoVariablesDto[]>(this.baseUrl + 'api/echovariables');
  //}
  public saveVariable(variable: EchoVariablesDto): Observable<EchoVariablesDto> {
    console.log('saveVariable', variable);
    if (variable.id != null && variable.id !== 0) {
      return this.http.put<EchoVariablesDto>(this.baseUrl + `api/echovariables/${variable.id}`, variable);
    }
    return this.http.post<EchoVariablesDto>(this.baseUrl + 'api/echovariables', variable);
  }

  /** GET variable by id. Will 404 if id not found */
  getVariable(id: number): Observable<EchoVariablesDto> {
    const url = this.baseUrl + `api/echovariables/${ id }`;
    return this.http.get<EchoVariablesDto>(url).pipe(
      tap(_ => (`fetched variable id=${id}`)));
  }
  public getAllGroups(): Observable<SysEchoGroupDto[]> {
    return this.http.get<SysEchoGroupDto[]>(this.baseUrl + 'api/echogroups');
  }
  public getAllVariablesForExamination(selectedVariables: string = ""): Observable<ViewEchoVariableDto[]> {
    var query = this.baseUrl + `api/echogroups/echogVarsExVy`;
    //Skicka strängen som parameter ok?
    if (selectedVariables != null) {
      query += `?selectedVariables=${selectedVariables}`;
    }
    return this.http.get<ViewEchoVariableDto[]>(query);
  }
  
  public getInfoSysInfEchoValueType(): Observable<SysInfEchoValueTypeDto[]> {
    return this.http.get<SysInfEchoValueTypeDto[]>(this.baseUrl + 'api/echovaluetype');
  }
  public getAllVariableUnits(): Observable<SysInfUnitDto[]> {
    return this.http.get<SysInfUnitDto[]>(this.baseUrl + 'api/echounit');
  }
  public getAllVariableFormats(): Observable<SysInfFormatDto[]> {
    return this.http.get<SysInfFormatDto[]>(this.baseUrl + 'api/echoformat');
  }
  public getAllMeasuremenMethods(): Observable<SysInfEchoMeasuremenMethodDto[]> {
    return this.http.get<SysInfEchoMeasuremenMethodDto[]>(this.baseUrl + 'api/echomeasuremenmethod');
  }
  
  public getInfEchoValueTypeList(id: number = 0): Observable<SysInfEchoValueTypeListDto[]> {
    if (id == null)
      return EMPTY;

    return this.http.get<SysInfEchoValueTypeListDto[]>(this.baseUrl + `api/echovaluetype/${id}`);
  }
  public getAllEchoVariablesByGroup(vyTypeId?: number, filterString: string = "", selectedVariables: string = ""): Observable<VariablesByGroupsDto[]> {
    console.log("variabler sträng" + selectedVariables);
    var query = this.baseUrl + `api/echogroups/echoGroupsAndVars`;
    if (vyTypeId > 0) {
      query += `?vytypeid=${vyTypeId}`;
    }
    //Skicka strängen som parameter ok?
    if (selectedVariables != null) {
      query += `&selectedVariables=${selectedVariables}`;
    }
    query += `&filter=¦${filterString}¦`;
    return this.http.get<VariablesByGroupsDto[]>(query);
  }
  public getEchoGroupsByType(id: number): Observable<SysEchoGroupDto[]> {
    if (id == null)
      return EMPTY;
    return this.http.get<SysEchoGroupDto[]>(this.baseUrl + `api/echogroups/echogroupsByType/${id}`);
  }
  public getEchoGroups(): Observable<SysEchoGroupDto[]> {
    return this.http.get<SysEchoGroupDto[]>(this.baseUrl + `api/echogroups/echogroupsByType`);
  }
  public getEchoVariablesAndGroup(): Observable<ViewEchoVariableDto[]> {
    return this.http.get<ViewEchoVariableDto[]>(this.baseUrl + `api/echogroups/echogroupAndVar`);
  }
  
  //skapa en servis för beräkningar?
  variablerForBerakningList$ = this.http.get<ListviewSysEchoVariableDto[]>(this.baseUrl + 'api/echovariables')
    .pipe(tap(data => console.log("Variabel ", JSON.stringify(data))));
  //Ligga i en ny service?
  
  public getCalculatedValue(grundData: GrunddataDto): Observable<any> {
    return this.http.post<GrunddataDto>(this.baseUrl + 'api/calculation', grundData);
  }
  //public getCalculatedKajsa(grundData: GrunddataDto, värde: number = 0): Observable<any> {
  //  return this.http.post<GrunddataDto>(this.baseUrl + `api/calculation/CalculateKajsa?varde=${värde}`, grundData);
  //}
  getUtlatandeText(grundData: GrunddataDto, värde: number = 0, metodId: number = 0): Observable<string> {
    var query = this.baseUrl + `api/calculation/UtlatandeText`;
    if (metodId > 0) 
      query += `?metodId=${metodId}`;
    if (värde > 0)
      query += `&varde=${värde}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'text'
    };
    return this.http.post(query, grundData, options);
  }
  public getBSA(grundData: GrunddataDto): Observable<any> {
    return this.http.post<GrunddataDto>(this.baseUrl + 'api/calculation/CalculateBsa', grundData);
  }
  //public getAllaMetoder(): Observable<BerakningsMetoder[]> {
  //  return this.http.get<BerakningsMetoder[]>('/Proj/PhysiologicNew/Physiologic/src/Physiologic.Calculation/Metoder/Data/BeräkningsMetoderJson.json')
  //}
  //public getCalculatedFormulaLinnea(metod: SaveBerakningsMetodLinnea, refTyp: number): Observable<any> {
  //  return this.http.post<SaveBerakningsMetodLinnea>(this.baseUrl + `api/calculation/FormulaCalculationLinnea?reftyp=${refTyp}`, metod);
  //}

  //public getBsaMetoder(): Observable<CalculationBsaDto[]> {
  //  return this.http.get<CalculationBsaDto[]>('/Proj/PhysiologicNew/Physiologic/src/Physiologic.Calculation/BSA/Data/CalculationBSA.json')
  //}

  //public getAllaEchoVariables(): Observable<ListviewSysEchoVariableDto[]> {
  //  return this.http.get<ListviewSysEchoVariableDto[]>(this.baseUrl + 'api/echovariables');
  //}
  // En minuts cachning av senaste "count" sökningen
  private getCount(uri: string): Observable<number> {
    console.log(uri);
    // Ny url => Nollställ
    if (uri != this.lastCount) {
      this.clearCount();
      this.lastCount = uri;
    }

    if (!this.totalItems) {
      // Hämta totala antalet poster och cacha det i 2 sekunder
      this.totalItems = this.http.get<number>(uri).pipe(
        map(result => result),
        shareReplay(1));

      this.countTimer = setTimeout(() => {
        this.clearCount();
      }, 2000);
    }

    return this.totalItems;
  }

  private clearCount() {
    if (this.countTimer != null)
      clearTimeout(this.countTimer);
    this.countTimer = null;
    this.totalItems = null;
    this.lastCount = "";
  }
  
}

export class Constants {
  id: number;
  description: string;
  value: number;
}
//export type ListviewSysEchoVariableDto = {
//  id: number;
//  sysDescription: string;
//  shortName: string;
//  short: string;
//  name: string;
//  description: string;
//  unitShort: string;
//  format: string;
//  option: string;
//  sysInfEchoOptionId: number;
//  active?: boolean;
//}

