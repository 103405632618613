import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { faChevronUp, faChevronDown, faTrash, faCopy, faPaste, faInfoCircle, faCalculator, faPlusCircle, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Clipboard } from "@angular/cdk/clipboard";
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/

import { OnInit } from '@angular/core';
import { CalculationService, FormTecken, Formel, Formular, Gradering, GrunddataDto, SysInfComparisonSymbol, Tecken, VariabelFormularFormController } from '../../../core/services/calculation.service';
import { EchoVariablesDto } from '../../../model/undersokningsvarianter/echo-variables-dto';
import { Observable } from 'rxjs';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gradering-formel-echovariable-vers2',
  templateUrl: './gradering-formel-echovariable-vers2.component.html',
  styleUrls: ['./gradering-formel-echovariable-vers2.component.scss']
})
export class GraderingFormelEchoVariableVars2Component implements OnInit, AfterContentChecked {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private changeDetector: ChangeDetectorRef,
    private service: CalculationService
  ) { }
  //Ikoner
  faPlusCircle = faPlusCircle;
  faTrash = faTrash;
  faCopy = faCopy;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faPaste = faPaste;
  faInfoCircle = faInfoCircle;
  faCalculator = faCalculator;
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  id: number;
  @Input() intervallTyp: number;
  @Input() format: string;
  @Input() konTyp: number;
  @Input() kon: number;
  @Input() group: Formular;
  @Input() grunddata: GrunddataDto;
  @Output() grunddataOpen = new EventEmitter<boolean>();
  @Output() allFormulaData = new EventEmitter<VariabelFormularFormController>();
  @Output() message = new EventEmitter<string>();
  @Input() cVariable: EchoVariablesDto;
  @Input() formChanged: boolean;

  //Graderingar
  graderingarLista: Gradering[] = [
    { id: 1, comparisonSymbol: '', shortDesc: 'Uttalat', description: '{variabel} uttalat', tooltip: '{variabel} uttalat', intervalltyp: 2, graderingsGrupp: 1 },
    { id: 2, comparisonSymbol: '', shortDesc: 'Måttligt', description: '{variabel} måttligt', tooltip: '{variabel} måttligt', intervalltyp: 2, graderingsGrupp: null },
    { id: 5, comparisonSymbol: '', shortDesc: 'Lätt', description: '{variabel} lätt', tooltip: '{variabel} lätt', intervalltyp: 2, graderingsGrupp: null },
    { id: 13, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 2, graderingsGrupp: null, isMedelvarde: true },
    { id: 7, comparisonSymbol: '', shortDesc: 'Normalområde', description: '{variabel} är normalt.', tooltip: '{variabel} är normalt', intervalltyp: 2, graderingsGrupp: null },
    //{ id: 91, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området!', intervalltyp: 2, graderingsGrupp: null, deleted: true },
    { id: 14, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 1, graderingsGrupp: null, isMedelvarde: true },
    { id: 9, comparisonSymbol: '≥', shortDesc: 'Övre normalområde', description: '{variabel} övre normalområde', tooltip: '{variabel} övre normalområde', intervalltyp: 1, graderingsGrupp: null },
    { id: 10, comparisonSymbol: '', shortDesc: 'Normalområde', description: '{variabel} är normalt', tooltip: '{variabel} är normalt', intervalltyp: 1, graderingsGrupp: null },
    //{ id: 12, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området', intervalltyp: 1, graderingsGrupp: null, hide: true }
  ];
  //Valideringstexter (som inte används)
  /*validation_messages: ValidationErrors = [{ id: 1, type: 'maxLength', message: 'För många tecken.' },
      { id: 2, type: 'pattern', message: 'Fältet får bara innehålla siffror och kommatecken' },
      { id: 3, type: 'validateCompareIntervalUnder', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 4, type: 'validateCompareIntervalOver', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 5, type: 'min', message: 'Omöjligt min-värde' },
      { id: 6, type: 'max', message: 'Omöjligt max-värde' },
      { id: 7, type: 'required', message: 'Du måste ange ett värde' },
      { id: 8, type: 'parantesesCount', message: "Formlen måste innehålla lika många '(' som ')'" }];*/

  intervallFormTecken: FormTecken;
  submitted = false;
  editVariableGraderingForm = this.fb.group({
    graderingar: this.fb.array([])
  });
  //////////
  ///
  ngOnInit() {
    this.format = this.format?.replaceAll(/#/g, "X");
    //Sätt texter med alias
    this.seGraderingstexterMedAlias();
    //this.setForm();
    this.graderingarLista.forEach(_ => {
      if (_.intervalltyp == this.intervallTyp)
        this.addGradering(_.id, _.comparisonSymbol, "", _.description, _.shortDesc, _.tooltip, _.intervalltyp, _.deleted == true ? true : false, _.graderingsGrupp, _.hide, _.isMedelvarde, _.hasVarde, _.hasUnderOverVarde, _.underOverVarde);
    });
    this.setGradering();

    this.changeDetector.detectChanges();
  }
  //////////
  ///
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  //////////
  ///
  //setForm() {
  //  let formTecken: FormTecken = {
  //    konId: this.kon,
  //    intervalltyp: this.intervallTyp,
  //    graderingar: this.graderingarLista.filter(_ => _.intervalltyp === this.intervallTyp)
  //  }
  //  this.intervallFormTecken = formTecken;
  //}

  //////////
  ///
  seGraderingstexterMedAlias() {
    //Visa med variabelnamn
    this.graderingarLista.forEach(_ => {
      _.klarText = _.description.replaceAll("{variabel}", this.cVariable.shortName);
      _.tooltip = _.tooltip.replaceAll("{variabel}", this.cVariable.shortName);
    });

  }
  //////////
  ///
  showFormula(e: any, index: number, typ: number, kon: number) {
    this.graderingar.controls[index].get("formula").setValue(e.formel);
    //if (e.showErrorMessageParanteses)
    //  this.graderingar.controls[index].get("formula").setErrors({ 'parantesesCount': true })
  }
  //////////
  ///
  showFormulaAnswer(e: any, teckenId: number, konId: number) {
    console.log("Svar: " + e);
  }
  //////////
  ///
  get graderingar() {
    return this.editVariableGraderingForm.get('graderingar') as FormArray;
  }
  //////////
  ///
  //deleteGradering(index: number) {
  //  const formel: VariabelFormularFormController = {
  //    beskrivning: this.graderingar.controls[index].get("description").value,
  //    //TODO: Reftypid istället?
  //    id: this.graderingar.controls[index]?.get("id").value,
  //    formel: null,
  //    /*startValue: null,*/
  //    slutValue: this.graderingar.controls[index]?.get("slutValue")?.value ?? false,
  //    visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
  //    intervallTyp: this.intervallTyp,
  //    graderingsTecken: null,
  //    graderingsTyp: this.graderingar.controls[index]?.get("id").value,
  //    graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
  //    //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
  //    konId: this.kon,
  //    deleted: true
  //  };
  //  this.allFormulaData.emit(formel);

  //  //Radera även graderingen som hänger ihop i samma grupp
  //  if (this.graderingar.controls[index]?.get("graderingsGrupp")?.value != null) {
  //    this.graderingar.controls.forEach(_ => {
  //      if (_.get("graderingsGrupp")?.value == this.graderingar.controls[index]?.get("graderingsGrupp")?.value) {

  //        //Grdering som hänger ihop med den formeln som raderas
  //        const koppladGradering: VariabelFormularFormController = {
  //          beskrivning: null,
  //          //TODO: Reftypid istället?
  //          id: _.get("id")?.value,
  //          formel: null,
  //          /*startValue: null,*/
  //          slutValue: _.get("slutValue")?.value,
  //          visaEjISvar: null,
  //          intervallTyp: this.intervallTyp,
  //          graderingsTyp: _.get("id")?.value,
  //          graderingsText: _.get("utlatande")?.value,
  //          graderingsTecken: null,
  //          //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
  //          konId: this.kon,
  //          deleted: true
  //        };
  //        _.get("formula")?.patchValue("");
  //        _.get("deleted")?.patchValue(true);
  //        this.allFormulaData.emit(koppladGradering);
  //      }
  //    });
  //  }


  //  //Rensa bort värdet
  //  this.graderingar.controls[index]?.get("formula")?.patchValue("");
  //  //Deletemarkera
  //  this.graderingar.controls[index]?.get("deleted")?.patchValue(true);
  //  //this.intervallFormTecken.tecken.find(_ => _.selectedControlIndex === index).selected = false;
  //  //this.graderingar.removeAt(index);
  //  this.graderingar.controls.forEach((_, i) => {
  //    this.getTecken(i);
  //  });
  //}
  //////////
  ///
  takeBackGradering(index: number) {
    //Radera även graderingen som hänger ihop i samma grupp
    if (this.graderingar.controls[index]?.get("graderingsGrupp")?.value != null) {
      this.graderingar.controls.forEach(_ => {
        if (_.get("graderingsGrupp")?.value == this.graderingar.controls[index]?.get("graderingsGrupp")?.value) {
          _.get("formula")?.patchValue("");
          _.get("deleted")?.patchValue(false);
        }
      });
    }
    else {
      this.graderingar.controls[index]?.get("deleted")?.patchValue(false);
    }
  }
  underOverChange(index: number, input: any) {
    let formel: Formel;
    //let overvade = this.graderingar.controls[index].get("underOverVarde").value;

    let mittenvarde = this.graderingar.controls[index].get("formula")?.value.replaceAll(",", ".");

    let overvarde = Number(mittenvarde) + Number(input);
    let undervarde = Number(mittenvarde) - Number(input);
    //let overvarde = { formel: mittenvarde.replaceAll(",", ".") + "+" + input.replaceAll(",", "."), format: this.format };
    //let undervarde = { formel: mittenvarde.replaceAll(",", ".") + "-" + input.replaceAll(",", "."), format: this.format };

    //this.service.calculateoverunder(overvarde).subscribe(data => {
    this.graderingar.controls[index].get("calculatedOverVarde")?.patchValue(overvarde);
    //});
    //this.service.calculateoverunder(undervarde).subscribe(data => {
    this.graderingar.controls[index].get("calculatedUnderVarde")?.patchValue(undervarde);
    //});
    this.getPreviousControlValue();
    const f: VariabelFormularFormController = {
      beskrivning: this.graderingar.controls[index].get("description").value,
      //TODO: Reftypid istället?
      id: this.graderingar.controls[index]?.get("id").value,
      formel: this.graderingar.controls[index]?.get("formula").value,
      //formel: this.graderingar.controls[index]?.get("formula").value,
      //startValue: this.graderingar.controls[index]?.get("startValue").value.toString(),
      slutValue: this.graderingar.controls[index]?.get("slutValue").value.toString(),
      visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTyp: this.graderingar.controls[index]?.get("id").value,
      graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
      graderingsTecken: this.graderingar.controls[index]?.get("tecken")?.value,
      //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      konId: this.kon,
      deleted: false,
      underOverUtlatande: this.graderingar.controls[index]?.get("underOverUtlatande")?.value,
      underOverVarde: this.graderingar.controls[index]?.get("underOverVarde")?.value
    };
    this.allFormulaData.emit(f);
  }
  //////////
  ///
  formulaChange(index: number, input: string = "") {

    // TODO: Validera - Kolla min och max. Kolla så att värdet är högre respektive lägre än
    if (input != "")
      this.graderingar.controls[index]?.get("formula").patchValue(input);
    console.log(this.graderingar.controls[index]?.get("formula").value);
    

    ////let isFirstActiveGradering: boolean = this.graderingar.controls.find((_, i) => _.get("deleted").value == false && i < index)[0];
    ////let isLastActiveGradering: boolean = this.graderingar.controls.find((_, i) => _.get("deleted").value == false && i > index)[0];
    //let tecken: string = this.graderingar.controls[index]?.get("tecken")?.value;
    //  //tecken = isFirstActiveGradering && !isLastActiveGradering ? ">=" : this.graderingar.controls[index]?.get("tecken")?.value, isFirstActiveGradering && !isLastActiveGradering ? "<=" : this.graderingar.controls[index]?.get("tecken")?.value;

    const formel: VariabelFormularFormController = {
      beskrivning: this.graderingar.controls[index].get("description").value,
      //TODO: Reftypid istället?
      id: this.graderingar.controls[index]?.get("id").value,
      formel: this.graderingar.controls[index]?.get("formula").value,
      //formel: this.graderingar.controls[index]?.get("formula").value,
      //startValue: this.graderingar.controls[index]?.get("startValue").value.toString(),
      slutValue: this.graderingar.controls[index]?.get("slutValue").value.toString(),
      visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTyp: this.graderingar.controls[index]?.get("id").value,
      graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
      graderingsTecken: this.graderingar.controls[index]?.get("tecken")?.value,
      //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      konId: this.kon,
      deleted: false,
      underOverUtlatande: this.graderingar.controls[index]?.get("underOverUtlatande")?.value,
      underOverVarde: this.graderingar.controls[index]?.get("underOverVarde")?.value
    };
    this.uppdateraGraderingsText(index, "utlatande", "graderingstextKlartext");
    this.uppdateraGraderingsText(index, "underOverUtlatande", "underOverVardeKlartext");
    console.table(formel);
    this.allFormulaData.emit(formel);
    if (this.graderingar.controls[index].get("underOverVarde")?.value != undefined)
      this.underOverChange(index, this.graderingar.controls[index].get("underOverVarde")?.value)
    this.getPreviousControlValue();
  }
  //////////
  ///
  //visaEjISvarChange(index: number) {
  //  const formel: VariabelFormularFormController = {
  //    //beskrivning: this.graderingar.controls[index].get("description").value,
  //    reftypId: Number(this.graderingar.controls[index].get("tecken").value),
  //    formel: this.graderingar.controls[index].get("formula").value,
  //    visaEjISvar: this.graderingar.controls[index].get("hideInSvar")?.value ?? false,
  //    intervallTyp: this.intervallTyp,
  //    graderingsTyp: this.graderingar.controls[index].get("id")?.value,
  //    graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
  //    //delete: false
  //  };
  //  this.allFormulaData.emit(formel);
  //}
  //////////
  ///
  uppdateraGraderingsText(index: number, kontroll: string, klartexten: string) {
    let utlatandetext = this.graderingar.controls[index]?.get(kontroll)?.value.replaceAll("{variabel}", this.cVariable.shortName);
    this.graderingar.controls[index]?.get(klartexten)?.patchValue(utlatandetext);
  }
  //////////
  ///
  setGradering() {
    // Om det finns sparade graderingar

    //if (this.cVariable?.calculationJson?.formler) {
    //  this.group.formcontroller.forEach(g => {
    //    this.cVariable.calculationJson.formler.sort((a, b) => (a.graderingstyp > b.graderingstyp) ? 1 : -1).filter(f => f.kon == this.kon).flatMap((_, index) => {
    //      //this.cVariable.calculationJson.intervalltyp == this.intervallTyp
    //      this.graderingar.controls.forEach(g => {
    //        if (_.graderingstyp == g.get("id").value && _.intervalltyp == this.intervallTyp) {

    //          g?.get("utlatande").patchValue(_.graderingsText);
    //          let utlatande = g?.get("utlatande")?.value.replaceAll("{variabel}", this.cVariable.shortName);
    //          let uotlatande = g?.get("underOverVarde")?.value?.replaceAll("{variabel}", this.cVariable.shortName);
    //          g?.get("formula").patchValue(_.formel);
    //          //g?.get("startValue").patchValue(_.startValue);
    //          g?.get("slutValue").patchValue(_.slutValue);
    //          g?.get("deleted").patchValue(_.deleted);
    //          g?.get("hide").patchValue(_.hide);
    //          g?.get("isMedelvarde").patchValue(_.isMedelvarde);
    //          g?.get("hideInSvar")?.value ?? false;
    //          g?.get("graderingstextKlartext")?.patchValue(utlatande);
    //          g?.get("underOverVardeKlartext")?.patchValue(uotlatande);
    //          g?.get("hide")?.patchValue(_.hide);
    //          g?.get("isMedelvarde")?.patchValue(_.isMedelvarde);
    //          if (_.underOverVarde != "") {
    //            g?.get("hasUnderOverVarde")?.patchValue(true);
    //          }
    //          g?.get("underOverVarde")?.patchValue(_.underOverVarde);
    //          g?.get("underOverUtlatande")?.patchValue(_.underOverUtlatande);
    //          //this.graderingar.controls[index]?.get("id")?.patchValue(_.graderingstyp);
    //          this.graderingar.controls[index]?.get("kon")?.patchValue(_.kon);
    //          const formel: VariabelFormularFormController = {
    //            graderingsText: _.graderingsText,
    //            graderingsTecken: _.graderingsTecken,
    //            beskrivning: _.graderingsText,
    //            reftypId: _.graderingstyp,
    //            formel: _.formel,
    //            visaEjISvar: _.visaEjISvar,
    //            intervallTyp: this.intervallTyp,
    //            graderingsTyp: _.graderingstyp,
    //            konId: _.kon,
    //            //startValue: _.startValue,
    //            deleted: _.deleted,
    //            hide: _.hide,
    //            isMedelvarde: _.isMedelvarde,
    //            slutValue: _.slutValue,
    //            underOverUtlatande: _.underOverUtlatande,
    //            underOverVarde: _.underOverVarde
    //          };
    //          //this.selectTecken(formel.reftypId, index);
    //          this.allFormulaData.emit(formel);
    //        }
    //        if (_.underOverVarde) {
    //          this.underOverChange(index, _.underOverVarde);
    //        }
    //      })
    //    });
    //  });
    //}
    ////Annars
    //else {
    //  this.graderingar.controls.forEach(g => {;
    //    g?.get("graderingstextKlartext")?.patchValue(this.graderingarLista.filter(_ => _.id == g.get("id")?.value)[0].klarText);
    //    g?.get("underOverVardeKlartext")?.patchValue(this.graderingarLista.filter(_ => _.id == g.get("id")?.value)[0].uoklarText);
    //  });
    //}
  }
  //////////
  ///
  hideChange(index: number) {
    //TODO: Fixa
  }
  //////////
  ///
  addGradering(id: number, tecken: string, formula: string, description: string, shortDesc: string, tooltip: string, intervalltyp: number, deleted: boolean, graderingsGrupp: number, hide: boolean, isMedelvarde: boolean, hasVarde: boolean, hasUnderOverVarde: boolean, underOverVarde: string) {
    const graderingForm = this.fb.group({
      id: id,
      tooltip: tooltip,
      description: description,
      tecken: tecken,
      formula: [formula],
      /*startValue: "",*/
      utlatande: [description],
      hideInSvar: [false],
      intervalltyp: intervalltyp,
      deleted: deleted,
      graderingstextKlartext: "",
      underOverVardeKlartext: "",
      shortDesc: shortDesc,
      graderingsGrupp: graderingsGrupp,
      hide: hide,
      isMedelvarde: isMedelvarde,
      hasVarde: hasVarde != false ? true : false,
      hasUnderOverVarde: hasUnderOverVarde,
      underOverVarde: [underOverVarde, [Validators.max(this.cVariable.impossibleValueMax), Validators.min(this.cVariable.impossibleValueMin), Validators.pattern(/^[0-9]+\.?[0-9]*$/), Validators.maxLength(this.cVariable.format?.length)]],
      underOverUtlatande: "",
      calculatedOverVarde: "",
      calculatedUnderVarde: "",
      slutValue: ""
    });
    this.graderingar.controls.forEach((control, index) => {
      control.get('formula').setValidators([
        Validators.max(this.cVariable.impossibleValueMax),
        Validators.min(this.cVariable.impossibleValueMin),
        Validators.pattern(/^[0-9]+\.?[0-9]*$/),
        Validators.maxLength(this.cVariable.format?.length)
        //this.validateCompareIntervalUnder(index)
      ]);
    });
    this.graderingar.push(graderingForm);
    //this.graderingar.controls.forEach((_, index) => {
    //  if(underOverVarde)
    //    this.underOverChange(index, underOverVarde);
    //});
  }
  //////////
  ///
  //private validateCompareIntervalUnder(index: number): ValidatorFn | null {
  //  //Fortsätt här med att validera så att värdet är lägre än ocan och högre än värdet under
  //  return function validate(): any {

  //    //Kolla värdet före
  //    let prevGradValue;

  //    if (index != -1)
  //      prevGradValue = this.graderingar.controls[index + 1]?.get('formula')?.value ?? null;
  //    if (prevGradValue != null)
  //      for (var i = this.graderingar.controls.length; i--;) {
  //        if (i < index && this.graderingar.controls[i]?.get("formula")?.value > prevGradValue && this.graderingar.controls[i]?.get("deleted")?.value != true)
  //          return false;
  //        else
  //          return true;
  //      }
  //    return null;
  //  };
  //}

  //////////
  ///
  //private validateCompareIntervalOver(id: number): ValidatorFn | null {
  //  //Fortsätt här med att validera så att värdet är lägre än ocan och högre än värdet under
  //  return function validate(): any {
  //    let index = this.graderingar.controls.findIndex(_ => _.get('id').value == id) ?? -1;
  //    //Kolla värdet före
  //    let nextGradValue;
  //    if (index != -1)
  //      nextGradValue = this.graderingar.controls[index - 1]?.get('formula')?.value ?? null;
  //    if(nextGradValue != null)
  //    for (var i = this.graderingar.controls.length; i--;) {
  //      if (i < index && this.graderingar.controls[i]?.get("formula")?.value > nextGradValue && this.graderingar.controls[i]?.get("deleted")?.value != true)
  //        return false;
  //      else
  //        return true;
  //      }
  //    return null;
  //  }
  //}
  //////////
  ///
  showHideGrunddata(showGrunddata: boolean) {
    if (showGrunddata == true)
      this.grunddataOpen.emit(true);
    else
      this.grunddataOpen.emit(false);
  }
  //////////
  ///
  showMessage(message: string) {
    this.message.emit(message);
  }
  //////////
  ///
  showGrunddataMessage(text: string) {
    this.message.emit(text);
  }
  //////////
  ///
  copyFormula(formula: string) {
    this.clipboard.copy(formula);
  }
  //////////
  ///
  pasteFormula(controlindex: number) {
    //let copiedText = navigator.clipboard.read();
    this.graderingar.controls[controlindex].get("formula").setValue('kopierad text ska vara här');
    //let copiedText = navigator.clipboard.readText();
    //navigator.clipboard.readText().then(clipText => {
    //  console.log(clipText);
    //});
  }
  //////////
  ///
  //getStartValue(index: number): number | null {
  //  let startInterval;
  //  for (var i = this.graderingar.controls.length; i--;) {
  //    if (this.graderingar.controls[i]?.get("formula")?.value != "" && this.graderingar.controls[i]?.get("deleted")?.value != true) {
  //      if (i < index) {
  //        //Beroende på formatet ska 1 dras bort från sista decimalen
  //        let format = this.format.replace(/X/g, '0');
  //        let numberToTakeAway: string = format.slice(0, -1) + '1';
  //        startInterval = Number(this.graderingar.controls[i].get("formula").value) - Number(numberToTakeAway);
  //        this.graderingar.controls[index].get("startValue").patchValue(startInterval);
  //        this.formulaChange(index);
  //        break;
  //      }
  //      //TODO: Sätt även symboler på första och sista graderingen. 
  //      //else {
  //      //  this.formulaChange(index);
  //      //  break;
  //      //}
  //    }
  //  }
  //  //console.log("startvalue" + startInterval);
  //  return startInterval;
  //}
  //////////
  ///
  getTecken(index: number): string {
   //TODO: Fortsätt här att fixa med tecknen.

    let hide = this.graderingar.controls[index]?.get("hide").value;
    let isMedelvarde = this.graderingar.controls[index]?.get("isMedelvarde").value ?? false;
    const gradering = this.graderingarLista.filter((_, i) => _.isMedelvarde != true && _.hide != true && _.intervalltyp === this.intervallTyp);

    console.log("Visible" + !hide + "medelvärde" + isMedelvarde + gradering.length);
    if (index !== 0 && index !== gradering.length - 1 && hide != true && isMedelvarde != true) {
      return "-";
    } else if (index === 0 && !hide && !isMedelvarde) {
      this.graderingar.controls[index].get("tecken").patchValue("-");
      return "-";
    } else if (hide != true && isMedelvarde != true) {
      this.graderingar.controls[gradering.length - 1].get("tecken").patchValue("-");
      return "-";
    } else {
      return "";
    }
  }
  //////////
  ///
  //checkIfLastGradering(index: number): boolean {
  //let lastControl = this.graderingar.controls.length - 1;
  //const lastGraderingWithVarde = this.graderingar.controls
  //.slice()
  //.reverse()
  //.find((gradering, i) => gradering.get('hasVarde').value === true && i === index);

     
  //  if (lastGraderingWithVarde) {
  //    // Found the last gradering with hasVarde true
  //    return true;
  //  } else {
  //    // No gradering with hasVarde true found
  //    return false;
  //  }
  //}

  checkIfLastGradering(index: number): boolean {
    // Usage example
    const lastGraderingIndex = this.findLastIndexWithHasVarde(this.graderingar.controls);
    if (lastGraderingIndex === index) {
      // The current gradering is the last one with hasVarde true
      return true;
    } else {
      // The current gradering is not the last one with hasVarde true
      return false;
    }
  }
// Find the last index of the gradering with hasVarde true
 findLastIndexWithHasVarde(graderingar: AbstractControl[]): number {
  for (let i = graderingar.length - 1; i >= 0; i--) {
    if (graderingar[i].get('hasVarde').value === true) {
      return i;
    }
  }
  return -1; // Return -1 if no gradering with hasVarde true is found
}
  //////////
  ///
  openGradering(index: number, open: boolean) {
    this.graderingar.controls[index].get("hide")?.patchValue(open = !open)
  }
  //////////
  ///
  showParantesError(event: boolean, groupIndex: number) {
      //this.graderingar.controls[groupIndex].get("formula").setErrors({ 'parantesesCount': event });
  }

  //////////
  // Function to validate the formula control
  private validateCompareIntervalUnder(index: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentValue = control.value;
      const prevGradering = this.graderingar.controls[index + 1];

      if (prevGradering && currentValue > prevGradering.get('formula').value) {
        return { compareIntervalUnder: true };
      }

      return null;
    };

  }
  // Function to set startValue in this.graderingar
  //setStartValue(): void {
  //  this.graderingar.controls.forEach((control, index) => {
  //    const nextGradering = this.graderingar.controls[index + 1];
  //    if (nextGradering) {
  //      const calculatedOverVarde = nextGradering.get('calculatedOverVarde').value;

  //      control.get('startValue').setValue(calculatedOverVarde);
  //    }
  //  });
  //}
  getPreviousControlValue() {
    let previousCalculatedUnderVarde: any;
      let formula: any;

    this.graderingar.controls.forEach((control, index) => {

      for (let i = index - 1; i >= 0; i--) {
        let previousGradering = this.graderingar.controls[i];

        previousCalculatedUnderVarde = previousGradering.get('calculatedUnderVarde').value;
        formula = previousGradering.get('formula').value;

        if (previousCalculatedUnderVarde) {
          this.graderingar.controls[index].get("slutValue").setValue(previousCalculatedUnderVarde);
          //return previousCalculatedOverVarde;
          return;
        }
        else if (formula) {
          this.graderingar.controls[index].get("slutValue").setValue(formula);
          return;
        }
        // Perform operations on control
      }
      //for (let i = 0; i < index; i++) {
      //  let previousGradering = this.graderingar.controls[i];

      //  previousCalculatedUnderVarde = previousGradering.get('calculatedUnderVarde').value;
      //  formula = previousGradering.get('formula').value;

      //  if (previousCalculatedUnderVarde) {
      //    this.graderingar.controls[index].get("slutValue").setValue(previousCalculatedUnderVarde);
      //    return;
      //    //return previousCalculatedOverVarde;
      //  }
      //  else if (formula) {
      //    this.graderingar.controls[index].get("slutValue").setValue(formula);
      //    return;
      //  }
      //  else
      //    return;
      //  // Perform any desired operations on previousGradering
      //  // For example, you can access its properties or call methods on it
      //}

      //if (!previousCalculatedOverVarde) {
      //  this.graderingar.controls[index].get("slutValue").patchValue(formula);
      //  //return formula;
      //}
    });
  }
  changeHasVarde(index: number) {
    this.graderingar.controls[index].get("underOverVarde").reset();
    this.graderingar.controls[index].get("formula").reset();
    this.graderingar.controls[index].get("hasUnderOverVarde").reset();
    this.graderingar.controls[index].get("calculatedOverVarde").reset();
    this.graderingar.controls[index].get("calculatedUnderVarde").reset();
    
    this.getPreviousControlValue();
  }
  changeHasOverUnderVarde(index: number) {
    //Nollställ värdena som har med över och under att göra
    this.graderingar.controls[index].get("underOverVarde").reset();
    this.graderingar.controls[index].get("calculatedOverVarde").reset();
    this.graderingar.controls[index].get("calculatedUnderVarde").reset();
    this.getPreviousControlValue();
  }
  ///
  submitForm() { }
}
