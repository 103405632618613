import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { faChevronUp, faChevronDown, faTrash, faCopy, faPaste, faInfoCircle, faCalculator, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Clipboard } from "@angular/cdk/clipboard";
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/

import { OnInit } from '@angular/core';
import { CalculationService, FormTecken, Formular, Gradering, GrunddataDto, SysInfComparisonSymbol, Tecken, VariabelFormularFormController } from '../../../core/services/calculation.service';
import { EchoVariablesDto } from '../../../model/undersokningsvarianter/echo-variables-dto';
import { Observable } from 'rxjs';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gradering-formel-echovariable',
  templateUrl: './gradering-formel-echovariable.component.html',
  styleUrls: ['./gradering-formel-echovariable.component.scss']
})
export class GraderingFormelEchoVariableComponent implements OnInit, AfterContentChecked {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    private changeDetector: ChangeDetectorRef
  ) { }
  //Ikoner
  faPlusCircle = faPlusCircle;
  faTrash = faTrash;
  faCopy = faCopy;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faPaste = faPaste;
  faInfoCircle = faInfoCircle;
  faCalculator = faCalculator;

  id: number;
  @Input() intervallTyp: number;
  @Input() format: string;
  @Input() konTyp: number;
  @Input() kon: number;
  @Input() group: Formular;
  @Input() grunddata: GrunddataDto;
  @Output() grunddataOpen = new EventEmitter<boolean>();
  @Output() allFormulaData = new EventEmitter<VariabelFormularFormController>();
  @Output() message = new EventEmitter<string>();
  @Input() cVariable: EchoVariablesDto;
  @Input() formChanged: boolean;

  //Graderingar
  graderingarLista: Gradering[] = [
    { id: 1, comparisonSymbol: '', shortDesc: 'Måttligt till uttalat ög', description: '{variabel} måttligt till uttalat ög', tooltip: '{variabel} måttligt till uttalat övre gräns', intervalltyp: 2, graderingsGrupp: 1 },
    { id: 2, comparisonSymbol: '', shortDesc: 'Måttligt', description: '{variabel} måttligt', tooltip: '{variabel} uttalat', intervalltyp: 2, graderingsGrupp: null },
    { id: 3, comparisonSymbol: '', shortDesc: 'Måttligt till uttalat ng.', description: '{variabel} måttligt till uttalat ng.', tooltip: '{variabel} måttligt till uttalat nedre gräns', intervalltyp: 2, graderingsGrupp: 1 },
    { id: 4, comparisonSymbol: '', shortDesc: 'Lätt till måttligt ög.', description: '{variabel} lätt till måttligt ög.', tooltip: '{variabel} lätt till måttligt övre gräns', intervalltyp: 2, graderingsGrupp: 2 },
    { id: 5, comparisonSymbol: '', shortDesc: 'Lätt', description: '{variabel} lätt', tooltip: '{variabel} lätt', intervalltyp: 2, graderingsGrupp: null },
    { id: 6, comparisonSymbol: '', shortDesc: 'Lätt till måttligt ng', description: '{variabel} lätt till måttligt ng.', tooltip: '{variabel} lätt till måttligt nedre gräns', intervalltyp: 2, graderingsGrupp: 2 },
    { id: 13, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 2, graderingsGrupp: null, isMedelvarde: true },
    { id: 7, comparisonSymbol: '', shortDesc: 'Normalt', description: '{variabel} är normalt.', tooltip: '{variabel} är normalt', intervalltyp: 2, graderingsGrupp: null },
    { id: 8, comparisonSymbol: '', shortDesc: 'Nedre normalområde', description: '{variabel} nedre normalområde', tooltip: '{variabel} nedre normalområde', intervalltyp: 2, graderingsGrupp: null },
    { id: 9, comparisonSymbol: '', shortDesc: 'Övre normalområde', description: '{variabel} övre normalområde', tooltip: '{variabel} övre normalområde', intervalltyp: 1, graderingsGrupp: null },
    { id: 91, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området!', intervalltyp: 2, graderingsGrupp: null, deleted: true },
    { id: 14, comparisonSymbol: '', shortDesc: 'Medelvärde', description: '{variabel} medelvärde', tooltip: '{variabel} medelvärde', intervalltyp: 1, graderingsGrupp: null, isMedelvarde: true },
    { id: 9, comparisonSymbol: '', shortDesc: 'Övre normalområde', description: '{variabel} övre normalområde', tooltip: '{variabel} övre normalområde', intervalltyp: 1, graderingsGrupp: null },
    { id: 10, comparisonSymbol: '', shortDesc: 'Normalt', description: '{variabel} är normalt', tooltip: '{variabel} är normalt', intervalltyp: 1, graderingsGrupp: null },
    { id: 11, comparisonSymbol: '', shortDesc: 'Nedre normalområde', description: '{variabel} nedre normalområde', tooltip: '{variabel} nedre normalområde', intervalltyp: 1, graderingsGrupp: null },
    { id: 12, comparisonSymbol: '', shortDesc: 'Ett område till', description: '{variabel} nya området', tooltip: '{variabel} nya området', intervalltyp: 1, graderingsGrupp: null, hide: true }
  ];
  //Valideringstexter (som inte används)
  /*validation_messages: ValidationErrors = [{ id: 1, type: 'maxLength', message: 'För många tecken.' },
      { id: 2, type: 'pattern', message: 'Fältet får bara innehålla siffror och kommatecken' },
      { id: 3, type: 'validateCompareIntervalUnder', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 4, type: 'validateCompareIntervalOver', message: 'Värdena i graderingen måste komma i rätt ordning' },
      { id: 5, type: 'min', message: 'Omöjligt min-värde' },
      { id: 6, type: 'max', message: 'Omöjligt max-värde' },
      { id: 7, type: 'required', message: 'Du måste ange ett värde' },
      { id: 8, type: 'parantesesCount', message: "Formlen måste innehålla lika många '(' som ')'" }];*/

  intervallFormTecken: FormTecken;
  submitted = false;

  editVariableGraderingForm = this.fb.group({
    graderingar: this.fb.array([])
  });
  //////////
  ///
  ngOnInit() {
    this.format = this.format?.replaceAll(/#/g, "X");
    //Sätt texter med alias
    this.seGraderingstexterMedAlias();
    this.setForm();
    this.graderingarLista.forEach(_ => {
      if (_.intervalltyp == this.intervallTyp)
        this.addGradering(_.id, _.comparisonSymbol, "", _.description, _.shortDesc, _.tooltip, _.intervalltyp, _.deleted == true ? true : false, _.graderingsGrupp, _.hide, _.isMedelvarde);
    });
    this.setGradering();
    this.graderingar.controls.forEach((_, index) => {
      _.get("tecken").patchValue(this.getTecken(index));
    });
    this.changeDetector.detectChanges();
  }
  //////////
  ///
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  //////////
  ///
  setForm() {
    let formTecken: FormTecken = {
      konId: this.kon,
      intervalltyp: this.intervallTyp,
      graderingar: this.graderingarLista.filter(_ => _.intervalltyp === this.intervallTyp)
    }
    this.intervallFormTecken = formTecken;
  }

  //////////
  ///
  seGraderingstexterMedAlias() {
    //Visa med variabelnamn
    this.graderingarLista.forEach(_ => {
      _.klarText = _.description.replaceAll("{variabel}", this.cVariable.shortName);
      _.tooltip = _.tooltip.replaceAll("{variabel}", this.cVariable.shortName);
    });

  }
  //////////
  ///
  showFormula(e: any, index: number, typ: number, kon: number) {
    this.graderingar.controls[index].get("formula").setValue(e.formel);
    //if (e.showErrorMessageParanteses)
    //  this.graderingar.controls[index].get("formula").setErrors({ 'parantesesCount': true })
  }
  //////////
  ///
  showFormulaAnswer(e: any, teckenId: number, konId: number) {
    console.log("Svar: " + e);
  }
  //////////
  ///
  get graderingar() {
    return this.editVariableGraderingForm.get('graderingar') as FormArray;
  }
  //////////
  ///
  deleteGradering(index: number) {
    const formel: VariabelFormularFormController = {
      beskrivning: this.graderingar.controls[index].get("description").value,
      //TODO: Reftypid istället?
      id: this.graderingar.controls[index]?.get("id").value,
      formel: null,
      startValue: null,
      visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTecken: null,
      graderingsTyp: this.graderingar.controls[index]?.get("id").value,
      graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
      //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      konId: this.kon,
      deleted: true
    };
    this.allFormulaData.emit(formel);

    //Radera även graderingen som hänger ihop i samma grupp
    if (this.graderingar.controls[index]?.get("graderingsGrupp")?.value != null) {
      this.graderingar.controls.forEach(_ => {
        if (_.get("graderingsGrupp")?.value == this.graderingar.controls[index]?.get("graderingsGrupp")?.value) {

          //Grdering som hänger ihop med den formeln som raderas
          const koppladGradering: VariabelFormularFormController = {
            beskrivning: null,
            //TODO: Reftypid istället?
            id: _.get("id")?.value,
            formel: null,
            startValue: null,
            visaEjISvar: null,
            intervallTyp: this.intervallTyp,
            graderingsTyp: _.get("id")?.value,
            graderingsText: _.get("utlatande")?.value,
            graderingsTecken: null,
            //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
            konId: this.kon,
            deleted: true
          };
          _.get("formula")?.patchValue("");
          _.get("deleted")?.patchValue(true);
          this.allFormulaData.emit(koppladGradering);
        }
      });
    }


    //Rensa bort värdet
    this.graderingar.controls[index]?.get("formula")?.patchValue("");
    //Deletemarkera
    this.graderingar.controls[index]?.get("deleted")?.patchValue(true);
    //this.intervallFormTecken.tecken.find(_ => _.selectedControlIndex === index).selected = false;
    //this.graderingar.removeAt(index);
    this.graderingar.controls.forEach((_, i) => {
      this.getTecken(i);
    });
  }
  //////////
  ///
  takeBackGradering(index: number) {
    //Radera även graderingen som hänger ihop i samma grupp
    if (this.graderingar.controls[index]?.get("graderingsGrupp")?.value != null) {
      this.graderingar.controls.forEach(_ => {
        if (_.get("graderingsGrupp")?.value == this.graderingar.controls[index]?.get("graderingsGrupp")?.value) {
          _.get("formula")?.patchValue("");
          _.get("deleted")?.patchValue(false);
        }
      });
    }
    else {
      this.graderingar.controls[index]?.get("deleted")?.patchValue(false);
    }
  }
  //////////
  ///
  formulaChange(index: number, input: string = "") {
    // TODO: Validera - Kolla min och max. Kolla så att värdet är högre respektive lägre än
    if (input != "")
      this.graderingar.controls[index]?.get("formula").patchValue(input);
    console.log(this.graderingar.controls[index]?.get("formula").value);
    this.uppdateraGraderingsText(index);

    ////let isFirstActiveGradering: boolean = this.graderingar.controls.find((_, i) => _.get("deleted").value == false && i < index)[0];
    ////let isLastActiveGradering: boolean = this.graderingar.controls.find((_, i) => _.get("deleted").value == false && i > index)[0];
    //let tecken: string = this.graderingar.controls[index]?.get("tecken")?.value;
    //  //tecken = isFirstActiveGradering && !isLastActiveGradering ? ">=" : this.graderingar.controls[index]?.get("tecken")?.value, isFirstActiveGradering && !isLastActiveGradering ? "<=" : this.graderingar.controls[index]?.get("tecken")?.value;

    const formel: VariabelFormularFormController = {
      beskrivning: this.graderingar.controls[index].get("description").value,
      //TODO: Reftypid istället?
      id: this.graderingar.controls[index]?.get("id").value,
      formel: this.graderingar.controls[index]?.get("formula").value,
      //formel: this.graderingar.controls[index]?.get("formula").value,
      startValue: this.graderingar.controls[index]?.get("startValue").value.toString(),
      visaEjISvar: this.graderingar.controls[index]?.get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTyp: this.graderingar.controls[index]?.get("id").value,
      graderingsText: this.graderingar.controls[index]?.get("utlatande")?.value,
      graderingsTecken: this.graderingar.controls[index]?.get("tecken")?.value,
      //graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      konId: this.kon,
      deleted: false
    };
    this.uppdateraGraderingsText(index);
    console.table(formel);
    this.allFormulaData.emit(formel);
  }
  //////////
  ///
  visaEjISvarChange(index: number) {
    const formel: VariabelFormularFormController = {
      beskrivning: this.graderingar.controls[index].get("description").value,
      reftypId: Number(this.graderingar.controls[index].get("tecken").value),
      formel: this.graderingar.controls[index].get("formula").value,
      visaEjISvar: this.graderingar.controls[index].get("hideInSvar")?.value ?? false,
      intervallTyp: this.intervallTyp,
      graderingsTyp: this.graderingar.controls[index].get("id")?.value,
      graderingsGrupp: this.graderingar.controls[index]?.get("graderingsGrupp")?.value,
      //delete: false
    };
    this.allFormulaData.emit(formel);
  }
  //////////
  ///
  uppdateraGraderingsText(index: number) {
    let utlatandetext = this.graderingar.controls[index]?.get("utlatande")?.value.replaceAll("{variabel}", this.cVariable.shortName);
    this.graderingar.controls[index]?.get("graderingstextKlartext")?.patchValue(utlatandetext);
  }
  //////////
  ///
  setGradering() {
    // Om det finns sparade graderingar
    if (this.cVariable.calculationJson && this.cVariable.calculationJson.formler) {
      this.group.formcontroller.forEach(g => {
        this.cVariable.calculationJson.formler.sort((a, b) => (a.beraknat > b.beraknat) ? 1 : -1).filter(f => f.kon == this.kon).flatMap((_, index) => {
          //this.cVariable.calculationJson.intervalltyp == this.intervallTyp
          this.graderingar.controls.forEach(g => {
            if (_.id == g.get("id").value && _.intervalltyp == this.intervallTyp) {

              g?.get("utlatande").patchValue(_.graderingsText);
              let utlatande = g?.get("utlatande")?.value.replaceAll("{variabel}", this.cVariable.shortName);
              g?.get("formula").patchValue(_.formel);
              g?.get("startValue").patchValue(_.startValue);
              g?.get("deleted").patchValue(_.deleted);
              g?.get("isMedelvarde").patchValue(_.isMedelvarde);
              g?.get("hideInSvar")?.value ?? false;
              g?.get("graderingstextKlartext")?.patchValue(utlatande);
              g?.get("isMedelvarde")?.patchValue(_.isMedelvarde);
              g?.get("")
              //this.graderingar.controls[index]?.get("id")?.patchValue(_.graderingstyp);
              this.graderingar.controls[index]?.get("kon")?.patchValue(_.kon);
              const formel: VariabelFormularFormController = {
                graderingsText: _.graderingsText,
                graderingsTecken: _.graderingsTecken,
                beskrivning: _.graderingsText,
                formel: _.formel,
                visaEjISvar: _.visaEjISvar,
                intervallTyp: this.intervallTyp,
                konId: _.kon,
                startValue: _.startValue,
                deleted: _.deleted,
                isMedelvarde: _.isMedelvarde
              };
              //this.selectTecken(formel.reftypId, index);
              this.allFormulaData.emit(formel);
            }
          })
        });
      });
    }
    //Annars
    else {
      this.graderingar.controls.forEach(g => {
        g?.get("graderingstextKlartext")?.patchValue(this.graderingarLista.filter(_ => _.id == g.get("id")?.value)[0].klarText)
      });
    }
  }
  //////////
  ///
  hideChange(index: number) {
    //TODO: Fixa
  }
  //////////
  ///
  addGradering(id: number, tecken: string, formula: string, description: string, shortDesc: string, tooltip: string, intervalltyp: number, deleted: boolean, graderingsGrupp: number, hide: boolean, isMedelvarde: boolean) {
    const graderingForm = this.fb.group({
      id: id,
      tooltip: tooltip,
      description: description,
      tecken: tecken,
      formula: [formula, [Validators.required, Validators.max(Number(this.cVariable.impossibleValueMax)), Validators.min(Number(this.cVariable.impossibleValueMin)), Validators.pattern(/^[0-9]+\.?[0-9]*$/), Validators.maxLength(this.cVariable.format?.length)]],
      startValue: "",
      utlatande: [description, Validators.required],
      hideInSvar: [false, Validators.required],
      intervalltyp: intervalltyp,
      deleted: deleted,
      graderingstextKlartext: "",
      shortDesc: shortDesc,
      graderingsGrupp: graderingsGrupp,
      hide: hide,
      isMedelvarde: isMedelvarde,
    });
    this.graderingar.push(graderingForm);
  }
  //////////
  ///
  private validateCompareIntervalUnder(index: number): ValidatorFn | null {
    //Fortsätt här med att validera så att värdet är lägre än ocan och högre än värdet under
    return function validate(): any {

      //Kolla värdet före
      let prevGradValue;

      if (index != -1)
        prevGradValue = this.graderingar.controls[index + 1]?.get('formula')?.value ?? null;
      if (prevGradValue != null)
        for (var i = this.graderingar.controls.length; i--;) {
          if (i < index && this.graderingar.controls[i]?.get("formula")?.value > prevGradValue && this.graderingar.controls[i]?.get("deleted")?.value != true)
            return false;
          else
            return true;
        }
      return null;
    };
  }

  //////////
  ///
  //private validateCompareIntervalOver(id: number): ValidatorFn | null {
  //  //Fortsätt här med att validera så att värdet är lägre än ocan och högre än värdet under
  //  return function validate(): any {
  //    let index = this.graderingar.controls.findIndex(_ => _.get('id').value == id) ?? -1;
  //    //Kolla värdet före
  //    let nextGradValue;
  //    if (index != -1)
  //      nextGradValue = this.graderingar.controls[index - 1]?.get('formula')?.value ?? null;
  //    if(nextGradValue != null)
  //    for (var i = this.graderingar.controls.length; i--;) {
  //      if (i < index && this.graderingar.controls[i]?.get("formula")?.value > nextGradValue && this.graderingar.controls[i]?.get("deleted")?.value != true)
  //        return false;
  //      else
  //        return true;
  //      }
  //    return null;
  //  }
  //}
  //////////
  ///
  showHideGrunddata(showGrunddata: boolean) {
    if (showGrunddata == true)
      this.grunddataOpen.emit(true);
    else
      this.grunddataOpen.emit(false);
  }
  //////////
  ///
  showMessage(message: string) {
    this.message.emit(message);
  }
  //////////
  ///
  showGrunddataMessage(text: string) {
    this.message.emit(text);
  }
  //////////
  ///
  copyFormula(formula: string) {
    this.clipboard.copy(formula);
  }
  //////////
  ///Coding With Minecraft
  pasteFormula(controlindex: number) {
    //let copiedText = navigator.clipboard.read();
    this.graderingar.controls[controlindex].get("formula").setValue('kopierad text ska vara här');
    //let copiedText = navigator.clipboard.readText();
    //navigator.clipboard.readText().then(clipText => {
    //  console.log(clipText);
    //});
  }
  //////////
  ///
  getStartValue(index: number): number | null {
    let startInterval;
    for (var i = this.graderingar.controls.length; i--;) {
      if (this.graderingar.controls[i]?.get("formula")?.value != "" && this.graderingar.controls[i]?.get("deleted")?.value != true) {
        if (i < index) {
          //Beroende på formatet ska 1 dras bort från sista decimalen
          let format = this.format.replace(/X/g, '0');
          let numberToTakeAway: string = format.slice(0, -1) + '1';
          startInterval = Number(this.graderingar.controls[i].get("formula").value) - Number(numberToTakeAway);
          this.graderingar.controls[index].get("startValue").patchValue(startInterval);
          this.formulaChange(index);
          break;
        }
        //TODO: Sätt även symboler på första och sista graderingen. 
        //else {
        //  this.formulaChange(index);
        //  break;
        //}
      }
    }
    //console.log("startvalue" + startInterval);
    return startInterval;
  }
  getTecken(index: number): string {
   //TODO: Fortsätt här att fixa med tecknen.

    let hide = this.graderingar.controls[index]?.get("hide").value;
    let isMedelvarde = this.graderingar.controls[index]?.get("isMedelvarde").value ?? false;
    const gradering = this.graderingarLista.filter((_, i) => _.isMedelvarde != true && _.hide != true && _.intervalltyp === this.intervallTyp);

    console.log("Visible" + !hide + "medelvärde" + isMedelvarde + gradering.length);
    if (index !== 0 && index !== gradering.length - 1 && hide != true && isMedelvarde != true) {
      return "-";
    } else if (index === 0 && !hide && !isMedelvarde) {
      this.graderingar.controls[index].get("tecken").patchValue("-");
      return "-";
    } else if (hide != true && isMedelvarde != true) {
      this.graderingar.controls[gradering.length - 1].get("tecken").patchValue("-");
      return "-";
    } else {
      return "";
    }
  }
  //getStartValueAndSetTecken(index: number): number | null {
  //  let startInterval;
  //  for (var i = this.graderingar.controls.length; i--;){
  //    if (this.graderingar.controls[i]?.get("formula")?.value != "" && this.graderingar.controls[i]?.get("deleted")?.value != true) {
  //      if (i < index) {
  //        //Beroende på formatet ska 1 dras bort från sista decimalen
  //        let format = this.format.replace(/X/g, '0');
  //        let numberToTakeAway: string = format.slice(0, -1) + '1';
  //        console.log("Att ta bort" + numberToTakeAway);
  //        startInterval = Number(this.graderingar.controls[i].get("formula").value) - Number(numberToTakeAway);
  //        this.graderingar.controls[index].get("startValue").patchValue(startInterval);
  //        this.formulaChange(index);
  //        break;
  //      }
  //      //else {
  //      //  this.formulaChange(index);
  //      //  break;
  //      //}
  //    }
  //  }
  //  //console.log("startvalue" + startInterval);
  //  return startInterval;
  //}
  //////////
  ///
  showParantesError(event: boolean, groupIndex: number) {
      //this.graderingar.controls[groupIndex].get("formula").setErrors({ 'parantesesCount': event });
  }
  //////////
  ///
  submitForm() { }
}
