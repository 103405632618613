

<form [formGroup]="editVariableGraderingForm">
  <div class="modal-body">
    <h2>Graderingar {{cVariable.description ? cVariable.description : cVariable.shortName}}</h2>
    <div formArrayName="graderingar">
      <cdk-accordion class="accordion mt-2" *ngFor="let graderingForm of graderingar.controls; let index=index">
        <cdk-accordion-item [formGroup]="graderingForm"
                            #accordionItem="cdkAccordionItem"
                            class="accordion-item"
                            role="button"
                            tabindex="0"
                            [attr.id]="'accordion-header-' + index"
                            [attr.aria-expanded]="accordionItem.expanded"
                            [attr.aria-controls]="'accordion-body-' + index" *ngIf="(graderingForm.value.isMedelvarde != true)">
          <!--Stängd-->
          <div class="accordion-item-header w-100 disable_pointer bg-white">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <b>{{graderingForm.value.shortDesc}}</b>
              </div>
              <div class="col-lg-4">
                <ng-container *ngIf="graderingForm.value.hide == true">
                  <!--Om det är ett gränsområde-->
                  {{graderingForm.value.underOverVarde > 0 ? "Gränsområde " + calculate(true, graderingForm.value.formula, graderingForm.value.underOverVarde) + "-" + calculate(false, graderingForm.value.formula, graderingForm.value.underOverVarde) : ""}}
                  <!--Annars är det ett område ovanför bara-->
                  {{graderingForm.value.formula > 0 ? "Gräns " + graderingForm.value.formula : ""}}
                  {{!(graderingForm.value.formula > null || graderingForm.value.underOverVarde > null) ? "Används ej" : ""}}

                </ng-container>
              </div>
                <div class="col-lg-4 d-flex justify-content-end">
                  <fa-icon [icon]="graderingForm.value.hide != true ? faChevronUp : faChevronDown" class="pointer fa-lg" (click)="openGradering(index, graderingForm.value.hide)" [ngbTooltip]="'Öppna'"></fa-icon>
                </div>
              </div>
            </div>
          <!--Aktiv gradering-->
          <div *ngIf="graderingForm.value.hide != true" class="accordion-item-header w-100 disable_pointer" [ngClass]="{'border border-5 border-primary': graderingForm.value.id == 7 || graderingForm.value.id == 10}">
            <div class="row">
              <div class="col-lg-4">
                <div class="row">
                  <!--<div class="col-lg-2 pt-4 d-flex justify-content-end px-2 py-2">
        <fa-icon [icon]="faInfoCircle" class="pointer" [ngbTooltip]="graderingForm.value.tooltip"></fa-icon>
      </div>-->
                  <div class="col-lg-12">
                    <input class="form-check-input" type="checkbox" value="" id="hasVarde{{index}}" formControlName="hasVarde" [checked]="hasVarde">
                    <label class="form-check-label px-1" for="hasVarde{{index}}">
                      Gränsvärde finns
                    </label>
                  </div>
                  <div class="row" *ngIf="graderingForm.value.hasVarde != false">
                    <!--<label [ngClass]="intervallTyp == 1 ? 'offset-lg-1' : ''" for="to-{{index}}">{{intervallTyp == 1 ? 'Formel' : 'Värde'}}:</label>-->
                    <div class="row">
                      <div class="col-lg-1 pt-1" *ngIf="intervallTyp == 1">
                        <fa-icon [icon]="faCalculator" class="pointer" [ngbTooltip]="'Skapa formel'" (click)="accordionItem.toggle()"></fa-icon>
                      </div>
                      <div class="col-lg-10" *ngIf="intervallTyp == 1">
                        <input id="to-{{index}}" placeholder="Ange {{intervallTyp == 1 ? 'formel' : 'värde'}}" *ngIf="graderingForm.value.formula != ''" type="text" formControlName="formula" [ngbTooltip]="'Redigera formel'" readonly class="form-control-plaintext pointer" (change)="formulaChange(groupIndex)" (click)="accordionItem.toggle()">
                        <button class="btn btn-xs mt-1" *ngIf="graderingForm.value.formula == '' && !accordionItem.expanded" (click)="accordionItem.toggle()">Skapa formel</button>
                      </div>
                      <div class="col-lg-12" *ngIf="intervallTyp == 2">
                        <input id="to-{{index}}" type="text" placeholder="Ange {{intervallTyp == 1 ? 'formel' : 'värde'}}" formControlName="formula" class="form-control" [mask]="format ?? 'XX'" (change)="formulaChange(index, $event.target.value)">
                        <!--<div *ngFor="let validation of validation_messages.formula">
              <div *ngIf="graderingForm.controls['formula']?.hasError(validation.type) && (graderingForm.controls['formula']?.dirty || graderingForm.controls['formula']?.touched)">
                {{ validation.message }}
              </div>
            </div>-->
                        <div *ngIf="graderingForm.get('formula').hasError('validateCompareIntervalUnder')" class="error-message text-warning">
                          Värdena i graderingen måste komma i rätt ordning.
                        </div>
                      </div>
                      <!--<div class="col-lg-1 pt-1" *ngIf="graderingForm.value.formula != '' && intervallTyp == 1">
            <fa-icon [icon]="faCopy" class="pointer" (click)="copyFormula(graderingForm.value.formula)" [ngbTooltip]="'Kopiera formel'"></fa-icon>
          </div>-->
                      <!--<div class="col-lg-1 pt-1" *ngIf="graderingForm.value.formula == '' && clipboard && intervallTyp == 1">
            <fa-icon [icon]="faPaste" class="pointer" (click)="pasteFormula(groupIndex)" [ngbTooltip]="'Klistra in formel'"></fa-icon>
          </div>-->
                    </div>
                  </div>
                  <div class="col-lg-12" *ngIf="graderingForm.value.hasVarde != false && graderingForm.value.formula">
                    <input class="form-check-input" type="checkbox" value="" id="hasUnderOverVarde{{index}}" formControlName="hasUnderOverVarde" (change)="changeHasOverUnderVarde(index)" [checked]="hasUnderOverVarde">
                    <label class="form-check-label px-1" for="hasUnderOverVarde{{index}}">
                      Har + -
                    </label>
                  </div>
                  <div class="row" *ngIf="graderingForm.value.hasVarde != false && graderingForm.value.formula">
                    <div class="col-lg-11" *ngIf="graderingForm.value.hasUnderOverVarde == true">
                      <!--<label class="form-label px-1" for="underOverVarde{{index}}">
            Värde + -:
          </label>-->
                      <input class="form-control" type="text" value="" placeholder="Ange + - (from-tom)" id="underOverVarde{{index}}" formControlName="underOverVarde" [mask]="format ?? 'XX'" (change)="underOverChange(index, $event.target.value)">
                    </div>
                  </div>
                </div>
                <!--<div class="col-lg-2 pt-4 d-flex justify-content-end text-right" *ngIf="graderingForm.value.id != 7 && !isLast && intervalltyp != 2">
      <input id="to-{{groupIndex}}" type="text" formControlName="startValue" class="form-control" hidden>


    </div>
    <div class="col-lg-2 pt-4">

    </div>-->


              </div>
              <!--<input id="to-{{groupIndex}}" type="text" formControlName="slutValue" class="form-control">-->
              <!--Gränserna-->
              <div class="col-lg-2 py-2" *ngIf="graderingForm.value.formula">
                <div class="row my-2">
                  <span class="placeholder col-12 bg-primary py-1" *ngIf="graderingForm.value.underOverVarde" [class.mt-5]="graderingForm.value.underOverVarde"></span>
                </div>
                <div class="row my-2">
                  <span class="placeholder col-12 bg-default py-1"></span>
                </div>
                <div class="row my-2">
                  <span class="placeholder col-12 bg-primary py-1" *ngIf="graderingForm.value.underOverVarde"></span>
                </div>
              </div>
              <div class="col-lg-1 align-middle" *ngIf="graderingForm.value.formula" [class.mt-5]="graderingForm.value.underOverVarde">
                <div class="row my-2">
                  <span class="placeholder col-12 bg-white">{{graderingForm.value.calculatedOverVarde}}</span>
                </div>
                <div class="row my-2">
                  <span class="placeholder col-12 bg-white">{{graderingForm.value.formula}}</span>
                </div>
                <div class="row my-2">
                  <span class="placeholder col-12 bg-white">{{graderingForm.value.calculatedUnderVarde}}</span>
                </div>
              </div>
              <!--/Gränserna-->
              <div [ngClass]="graderingForm.value.tecken == 1 ? 'col-lg-5': 'col-lg-5'">
                <div class="row">
                  <div class="col-2 pt-4 text-end">
                    <!--TODO: Lägg till denna om vi ska ha pil neråt för den sista graderingen-->
                    <!--<fa-icon [icon]="checkIfLastGradering(index) ? faArrowDown : faArrowUp" *ngIf="graderingForm.value.hasVarde != false"></fa-icon>-->
                    <fa-icon [icon]="faArrowUp" *ngIf="graderingForm.value.hasVarde != false"></fa-icon>
                  </div>
                  <div class="col-10">
                    <div *ngIf="graderingForm.value.hasVarde != false && graderingForm.value.formula > 0">
                      <label for="description-{{index}}">Utlåtande {{graderingForm.value.shortDesc}}:</label>
                      <input id="description-{{index}}" type="text" formControlName="utlatande" class="form-control" (change)="formulaChange(index)">
                      <small><i>{{graderingForm.value.graderingstextKlartext}}</i></small>
                    </div>
                    <div *ngIf="graderingForm.value.hasUnderOverVarde == true">
                      <label for="underOverUtlatande-{{index}}">Utlåtande gränsområde:</label>
                      <input id="underOverUtlatande-{{index}}" type="text" formControlName="underOverUtlatande" class="form-control" (change)="formulaChange(index)">
                      <small><i>{{graderingForm.value.underOverVardeKlartext}}</i></small>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="col-lg-2 pt-1" *ngIf="graderingForm.value.tecken == 1">
    <br>
    <input class="form-check-input" type="checkbox" value="" id="hideInSvar" (change)="visaEjISvarChange(index)" formControlName="hideInSvar" [checked]="visaEjISvar">
    <label class="form-check-label px-1" for="hideInSvar">
      Visa inte i svar
    </label>
  </div>-->
              <div class="col-lg-1 pt-3 d-flex justify-content-end" *ngIf="intervallTyp != 2" (click)="accordionItem.toggle()">
                <span class="example-accordion-item-description">
                  <!--<fa-icon [icon]="accordionItem.expanded ? faChevronUp: faChevronDown" class="pointer"></fa-icon>-->
                </span>
              </div>
            </div>
            {{formula}}
          </div>
          <div class="accordion-item-body"
               role="region"
               [style.display]="accordionItem.expanded ? '' : 'none'"
               [attr.id]="'accordion-body-' + index"
               [attr.aria-labelledby]="'accordion-header-' + index">
            <div class="row text-end mt-0 pt-0"><span (click)="accordionItem.toggle()" class="btn btn-link text-end">Dölj</span></div>
            <app-create-formula [type]="graderingForm.value.tecken"
                                [cVariable]="cVariable"
                                [formel]="graderingForm.value.formula"
                                [controlId]="index"
                                (formulaChanged)="formulaChange(index)"
                                (formula)="showFormula($event, index, graderingForm.value.tecken, group.konId)"
                                [grunddata]="grunddata"
                                (formulaAnswer)="showFormulaAnswer($event, graderingForm.value.tecken, group.konId)"
                                (openGrunddata)="showHideGrunddata($event)"
                                (message)="showMessage($event)"
                                (showParantesesError)="showParantesError($event, index)"></app-create-formula>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
    </div>
  </div>
</form>
