<form [formGroup]="variableForm">
  <div [ngClass]="style == 2 ? 'row' : 'row'" *ngIf="variable.valueTypeId != null">
    <!--Om det är tabellvisningen-->
    <div [ngClass]="style == 2 ? 'col-6' : ''" *ngIf="style == 2 || variable.showEdit">
      <div [ngClass]="variable.showEdit ? 'list-group' : ''" *ngFor="let metod of variable?.measurementMethods; index as i; let isLast=last">
        <!-- Om variabeln har olika metodmätningar-->
        <span (click)="showHideEdit(variable.showEdit = !variable.showEdit)" [ngClass]="metod.selected == true? 'fw-bold pointer' : 'pointer'">{{metod.name}} <span *ngIf="metod.value">({{metod.value}})</span><span *ngIf="!isLast">, </span></span>
        <ng-container *ngIf="variable.showEdit && style == 1">
          <label class="list-group-item">
            <input class="form-check-input me-1" type="radio" [value]="metod.id" name="metodRadio" formControlName="metodRadio" (change)="variableChangeInput($event.target.value, 2, metod.id)">
            <small>Vald</small>
            <input type="text" class="form-control-sm form-control" (keydown.enter)="variableChangeInput($event.target.value, 2, metod.id)" (validate)="variableChangeInput($event.target.value, 2, metod.id)" customAutofocus [autofocusBool]="(variable.showEdit && style == 1 && variable?.measurementMethods.selectedMethodId == metod.id) ? true: false" [mask]="variable.format ?? 'XX'" [dropSpecialCharacters]="false" (focusout)="variableChangeInput($event.target.value, 2, metod.id)" formControlName="metod{{metod.id}}">
            <!--[autofocus]="variable.showEdit"-->
            <!--TODO: Lägg till radering här-->
          </label>
        </ng-container>
      </div>
    </div>
    <!--Om det är listvisningen ska en symbol visas istället.-->
    <div [ngClass]="style == 2 ? 'col-6' : 'col-10'">
      <!--<a *ngIf="style == 2" (click)="showHideEdit(variable.showEdit = !variable.showEdit)">{{ variable.showEdit ? "Stäng": "Redigera värde" }}</a>-->
      <!--Kvalitativa val-->
      <!--[autofocusBool]="(variable.showEdit && style == 1 && !(variable?.measurementMethods.length > 0)) ? true: false"-->
      <div *ngIf="(variable.showEdit || valdGrupp == 3) && ((variable.valueTypeId > 0 && variable.valueTypeId < 7)) && style == 1">
        <!--<select class=" form-select-sm form-select" (change)="onChangeValgrupp(variable, $event, style)" (validate)="variableChangeInput($event.target.value, 2)" customAutofocusformControlName="valgrupp" (keydown)="onKeyPressedOption($event)">-->
        <select class="form-select-sm form-select" (change)="onChangeValgrupp(variable, $event, style)" (validate)="variableChangeInput($event.target.value, 2)" formControlName="currentValueSelect">
          <option value="">Välj alternativ</option>
          <option *ngFor="let option of typeSelectList" [value]="option.value">{{option.value}} | {{option.text}}</option>
        </select>
      </div>
      <div *ngIf="variable.showEdit && (variable.valueTypeId == 0 || variable.valueTypeId == 7) && !(variable?.measurementMethods.length > 0) && style == 1">
        <!--<input type="text" class="form-control-sm form-control pt-0 mt-0" (validate)="variableChangeInput($event.target.value, 2)" [mask]="variable.format ?? 'XX'" [dropSpecialCharacters]="false" customAutofocus [autofocusBool]="variable.showEdit && style == 1 ? true: false" [ngClass]="variable?.measurementMethods.length > 0 ? 'form-control-plaintext' : ''" (keydown.enter)="variableChangeInput($event.target.value, 1)" (focusout)="variableChangeInput($event.target.value, 1)" formControlName="currentValue">-->
        <input type="text" class="form-control-sm form-control pt-0 mt-0" (validate)="variableChangeInput($event.target.value, 2)" [mask]="variable.format ?? 'XX'" [dropSpecialCharacters]="false" [ngClass]="variable?.measurementMethods.length > 0 ? 'form-control-plaintext' : ''" (keydown.enter)="variableChangeInput($event.target.value, 1)" (focusout)="variableChangeInput($event.target.value, 1)" formControlName="currentValue">
        <small *ngIf="variable.indexedOnBSA" class="text-danger">En indexerad variabel kan inte ändras</small>
        <!--[autofocus]="variable.showEdit ? true : false"-->
        <!--[mask]="variable.format ?? 'XX'"-->
      </div>
    </div>
    <div class="col-12" [ngStyle]="{'margin-top': !variable.showEdit ? '-25px' : '5px', 'margin-bottom': !variable.showEdit ? '2px' : ''}" *ngIf="style == 1">
      <div class="row">
        <!--<div *ngIf="(style == 1 && variable?.measurementMethods?.length > 0) || variable.showEdit" class="col-8">
        <a [ngbTooltip]="'Visa metoder (' + variable?.measurementMethods.length + ')'" (click)="showHideEdit(variable.showEdit = !variable.showEdit)" *ngIf="!variable.showEdit" class="float-end"><fa-icon [icon]="faAtom"></fa-icon></a>-->
        <!--<a [ngbTooltip]="'Visa metoder'" (click)="showHideEdit(variable.showEdit = !variable.showEdit)" *ngIf="variable.showEdit && style == 1" class="float-end">Stäng</a>-->
        <!--</div>-->
        <!--<div [ngClass]="(style == 1 && variable?.measurementMethods?.length > 0) || variable.showEdit ? 'col-4': 'col-12'">-->
        <div class="col-12">
          <div ngbDropdown class="float-end">
            <button type="button" class="btn btn-xs btn-outline-primary" id="dropdownConfig" ngbDropdownToggle><fa-icon [icon]="faBars"></fa-icon></button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="showHideEdit(variable.showEdit = !variable.showEdit)">{{ variable.showEdit ? "Sluta redigera": "Redigera" }}</button>
              <button ngbDropdownItem>Visa metodbeskrivning</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div *ngIf="var_currentValue.errors.required">Obligatoriskt fält</div>-->
  <small *ngIf="cValue?.errors?.['max']" class="text-danger">Överstigit maxvärde</small>
  <small *ngIf="cValue?.errors?.['min']" class="text-danger">Under minvärde</small>
  <div *ngIf="variable.valueTypeId == null" class="col-6">
    <small class="text-danger pointer" (click)="openEditVariabel()" [ngbTooltip]="'Lägg till mätmetod'">Variabeln saknar metod för gradering</small>
  </div>
</form>

